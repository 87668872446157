import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { SEARCH_RESULTS_GRID_MARGIN } from '../constants';

const SearchResultsGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: -${SEARCH_RESULTS_GRID_MARGIN}px;
	width: 100%;
	justify-content: center;

	${media.desktop`
		padding: 0 10px;
		max-width: 1112px;
		margin-top: 12px;
	`};

	${media.desktop1440`
		padding: 0 70px;
		max-width: none;
	`};

	& + & {
		border-top: 1px solid ${props => props.theme.colors.border};
	}

	.search-result-card {
		margin: ${SEARCH_RESULTS_GRID_MARGIN}px;
		flex: 0 1 290px;
		flex: 0 1 auto;
		width: calc(50% - ${SEARCH_RESULTS_GRID_MARGIN * 2}px);

		${media.phone`
      .recipe-card-image-wrapper {
        min-height: 110px;
				& > img {
					min-height: 110px;
				}
      }
    `};

		${media.tablet`
			width: calc(100% / 3 - ${SEARCH_RESULTS_GRID_MARGIN * 2}px);
      .recipe-card-image-wrapper {
        min-height: 159px;
				& > img {
					min-height: 159px;
				}
      }
		`};

		${media.desktop`
			width: calc(25% - ${SEARCH_RESULTS_GRID_MARGIN * 2}px);
		`};
	}
`;

export default SearchResultsGrid;
