import * as React from 'react';
import styled from 'styled-components';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import Pill from 'styleguide/components/Selection/Pill/Pill';
import { mediaBreakpoints, media } from 'styleguide/helpers/media';
import { NameAndValue, NumberMap } from 'common/interfaces/common';
import { SearchFilterItem } from '../../interfaces';
import { IngredientSelectorField } from 'common/components/Applets/ProductFeatureBrowser/components/IngredientSelectors';
import { useIntl } from 'react-intl';

const SectionWrapper = styled.div`
	${media.desktop`
		display: flex;
		margin: ${({ theme }) => theme.grid.gutterInPx(-1)};
	`};
`;

const ColumnWrapper = styled.div`
	width: 100%;
	padding: 0 15px;
	margin: 10px auto 20px;

	@media (max-width: ${mediaBreakpoints.phone560}px) {
		div[class^='FieldWrapper'] {
			display: block;
		}

		div[class^='PillWrapper'] {
			margin-left: 0;
		}

		label {
			width: 100%;
			display: block;
			font-size: 16px;
		}
	}

	${media.desktop`
		padding: 0;
		margin: ${({ theme }) => theme.grid.gutterInPx()};
	`};

	.form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	}
`;

const IngredientSelectionWrapper = styled.div``;

const PillWrapper = styled.div`
	margin: ${({ theme }) => theme.grid.gutterInPx(-0.5)};
	padding: ${({ theme }) => theme.grid.gutterInPx(2)} 0;

	> .selected-filter {
		margin: ${({ theme }) => theme.grid.gutterInPx(0.5)};
	}
`;

export interface AllergenicFilterDialogProps {
	allergenics: SearchFilterItem[];
	allergenicsTitle: string;
	ingredients: SearchFilterItem[];
	ingredientsTitle: string;
	selectedFilters: NameAndValue[];
	filterCount: NumberMap;
	resultsCount: number;
	onItemSelect: (filterName: string, filterValue: string, selected: boolean) => void;
}

const AllergenicContainsName = 'yliherkkyys-sisaltaa';
const DoesntContainAllergenicName = 'yliherkkyys-eisisalla';

const OtherIngredientsContainsName = 'muut-sisaltaa';
const DoesntContainOtherIngredientsContainsName = 'muut-eisisalla';

const AllergenicFilterDialog: React.FC<AllergenicFilterDialogProps> = ({
	allergenics,
	selectedFilters,
	onItemSelect,
	allergenicsTitle,
	filterCount,
	ingredients,
	ingredientsTitle,
	resultsCount,
}) => {
	const intl = useIntl();

	const getGroupItems = (groups: SearchFilterItem[], itemsName: string) => {
		const selection = groups.find(g => g.name === itemsName);
		return selection ? selection.items : null;
	};

	const findSelectedFilters = (groups: SearchFilterItem[], itemsName: string) => {
		const items = getGroupItems(groups, itemsName);
		if (!items) {
			return [];
		}
		return selectedFilters
			.filter(sf => sf.name === itemsName && items.some(i => i.value === sf.value))
			.map(sf => ({
				name: sf.name,
				value: sf.value,
				title: (items.find(i => i.value === sf.value) as SearchFilterItem).title,
			}));
	};

	const onOptionSelect = (value: string, filter: string) => {
		onItemSelect(filter, value, !selectedFilters.some(sf => sf.name === filter && sf.value === value));
	};

	const mapItemsAndCount = (filters: SearchFilterItem[], filterName: string, isInclude: boolean) => {
		const filter = filters.find(a => a.name === filterName) as SearchFilterItem;

		return filter.items.map(f => ({
			title: f.title,
			slug: f.value,
			count: isInclude ? filterCount[f.value] || 0 : resultsCount - (filterCount[f.value] || 0),
		}));
	};

	const renderSelectedFilters = (groups: SearchFilterItem[], itemsName: string) => {
		const onRemove = (value: string) => onOptionSelect(value, itemsName);
		return findSelectedFilters(groups, itemsName).map(sf => (
			<Pill key={`${sf.name} ${sf.value}`} className="selected-filter" removeId={sf.value} onRemove={onRemove}>
				{sf.title}
			</Pill>
		));
	};

	return (
		<SectionWrapper>
			<ColumnWrapper>
				<SmallHeader>{allergenicsTitle}</SmallHeader>
				<IngredientSelectionWrapper>
					<IngredientSelectorField
						label={intl.formatMessage({ id: 'product_contains' })}
						filter={AllergenicContainsName}
						selection={mapItemsAndCount(allergenics, AllergenicContainsName, true)}
						onIngredientSelect={onOptionSelect}
						disableZeroCounts={true}
					/>
				</IngredientSelectionWrapper>
				<PillWrapper>{renderSelectedFilters(allergenics, AllergenicContainsName)}</PillWrapper>
				<IngredientSelectionWrapper>
					<IngredientSelectorField
						label={intl.formatMessage({ id: 'product_does_not_contain' })}
						filter={DoesntContainAllergenicName}
						selection={mapItemsAndCount(allergenics, DoesntContainAllergenicName, false)}
						onIngredientSelect={onOptionSelect}
						disableZeroCounts={true}
					/>
				</IngredientSelectionWrapper>
				<PillWrapper>{renderSelectedFilters(allergenics, DoesntContainAllergenicName)}</PillWrapper>
			</ColumnWrapper>
			<ColumnWrapper>
				<SmallHeader>{ingredientsTitle}</SmallHeader>
				<IngredientSelectionWrapper>
					<IngredientSelectorField
						label={intl.formatMessage({ id: 'product_contains' })}
						filter={OtherIngredientsContainsName}
						selection={mapItemsAndCount(ingredients, OtherIngredientsContainsName, true)}
						onIngredientSelect={onOptionSelect}
						disableZeroCounts={true}
					/>
				</IngredientSelectionWrapper>
				<PillWrapper>{renderSelectedFilters(ingredients, OtherIngredientsContainsName)}</PillWrapper>
				<IngredientSelectionWrapper>
					<IngredientSelectorField
						label={intl.formatMessage({ id: 'product_does_not_contain' })}
						filter={DoesntContainOtherIngredientsContainsName}
						selection={mapItemsAndCount(ingredients, DoesntContainOtherIngredientsContainsName, false)}
						onIngredientSelect={onOptionSelect}
						disableZeroCounts={true}
					/>
				</IngredientSelectionWrapper>
				<PillWrapper>{renderSelectedFilters(ingredients, DoesntContainOtherIngredientsContainsName)}</PillWrapper>
			</ColumnWrapper>
		</SectionWrapper>
	);
};

export default AllergenicFilterDialog;
