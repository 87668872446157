import * as React from 'react';
import IconFilter from './IconFilter';
import styled from 'styled-components';
import LogoIcon from 'styleguide/components/Logos/Logo/LogoIcon';
import { NameAndValue } from 'common/interfaces/common';
import RecipeCooperationIcon from '../../../Recipe/components/RecipeCooperationIcon';
import { useIntl } from 'react-intl';
import { SearchFilterName } from '../../types';

const IconFilterGrid = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin: 0 -6px;

	.icon-filter {
		flex: 1 1 159px;
		margin: 0 6px;

		> img {
			width: 64px;
			margin: 25px 0;
		}

		> svg {
			margin: 26px 0;
		}
	}
`;

enum AuthorFilterTypes {
	Valio = 'valio_koekeittio',
	Cooperation = 'vieraskauha',
}

export interface AuthorFilterDialogProps {
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

const AuthorFilterDialog: React.FC<AuthorFilterDialogProps> = ({ selectedFilters, onFilterClick }) => {
	const intl = useIntl();

	const onClick = (filterName: string, filterValue: string, selected: boolean) => {
		onFilterClick(filterName, filterValue, selected);
	};

	return (
		<IconFilterGrid>
			<IconFilter
				key="valio_koekeittio"
				filterName={SearchFilterName.author}
				filterValue={AuthorFilterTypes.Valio}
				title={intl.formatMessage({ id: 'search_filter_author_valio_kitchen' })}
				icon={<LogoIcon />}
				onFilterClick={onClick}
				selected={selectedFilters.some(sf => sf.value === AuthorFilterTypes.Valio)}
			/>
			<IconFilter
				key="vieraskauha"
				filterName={SearchFilterName.author}
				filterValue="vieraskauha"
				title={intl.formatMessage({ id: 'search_filter_author_cooperation' })}
				icon={<RecipeCooperationIcon size={40} />}
				onFilterClick={onClick}
				selected={selectedFilters.some(sf => sf.value === AuthorFilterTypes.Cooperation)}
			/>
		</IconFilterGrid>
	);
};

export default AuthorFilterDialog;
