import * as React from 'react';
import SearchFilter from './SearchFilter';
import SimpleFilterDialog from './filters/SimpleFilterDialog';
import Toggle from 'styleguide/components/Inputs/Toggle/Toggle';
import BakingBreadIcon from 'styleguide/components/Icons/AspectGroupIcons/BakingBreadIcon/BakingBreadIcon';
import BakingBunsIcon from 'styleguide/components/Icons/AspectGroupIcons/BakingBunsIcon/BakingBunsIcon';
import BakingCakesIcon from 'styleguide/components/Icons/AspectGroupIcons/BakingCakesIcon/BakingCakesIcon';
import BakingDoughIcon from 'styleguide/components/Icons/AspectGroupIcons/BakingDoughIcon/BakingDoughIcon';
import BakingMuffinsIcon from 'styleguide/components/Icons/AspectGroupIcons/BakingMuffinsIcon/BakingMuffinsIcon';
import {
	BakingSavouryPastryIcon,
	SweetPastryIcon,
	PartyEasterIcon,
	PartyXmasIcon,
	VegeIcon,
	JuiceIcon,
	PuddingIcon,
	ActiveSeniorIcon,
	CheeseTrayIcon,
	YogurtIcon,
	DietLowCarbIcon,
} from 'styleguide/components/Icons/AspectGroupIcons';
import SoupIcon from 'styleguide/components/Icons/AspectGroupIcons/SoupIcon/SoupIcon';
import MifuIcon from 'styleguide/components/Icons/AspectGroupIcons/MifuIcon/MifuIcon';
import DessertIcon from 'styleguide/components/Icons/AspectGroupIcons/DessertIcon/DessertIcon';
import MeatIcon from 'styleguide/components/Icons/AspectGroupIcons/MeatIcon/MeatIcon';
import FishIcon from 'styleguide/components/Icons/AspectGroupIcons/FishIcon/FishIcon';
import ChickenIcon from 'styleguide/components/Icons/AspectGroupIcons/ChickenIcon/ChickenIcon';
import MifuRecipeIcon from 'styleguide/components/Icons/AspectGroupIcons/MifuRecipeIcon/MifuRecipeIcon';
import VegeRecipeIcon from 'styleguide/components/Icons/AspectGroupIcons/VegeRecipeIcon/VegeRecipeIcon';
import MincedMeatIcon from 'styleguide/components/Icons/AspectGroupIcons/MincedMeatIcon/MincedMeatIcon';
import DietDairyFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietDairyFreeIcon/DietDairyFreeIcon';
import DietEggFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietEggFreeIcon/DietEggFreeIcon';
import DietLactoseFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietLactoseFreeIcon/DietLactoseFreeIcon';
import DietLowLactoseIcon from 'styleguide/components/Icons/AspectGroupIcons/DietLowLactoseIcon/DietLowLactoseIcon';
import DietLightIcon from 'styleguide/components/Icons/AspectGroupIcons/DietLightIcon/DietLightIcon';
import DietProteinIcon from 'styleguide/components/Icons/AspectGroupIcons/DietProteinIcon/DietProteinIcon';
import DietGlutenFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietGlutenFreeIcon/DietGlutenFreeIcon';
import DietVegetarianIcon from 'styleguide/components/Icons/AspectGroupIcons/DietVegetarianIcon/DietVegetarianIcon';
import DietLessSaltIcon from 'styleguide/components/Icons/AspectGroupIcons/DietLessSaltIcon/DietLessSaltIcon';
import EveryDayIcon from 'styleguide/components/Icons/AspectGroupIcons/EveryDayIcon/EveryDayIcon';
import FamilyIcon from 'styleguide/components/Icons/AspectGroupIcons/FamilyIcon/FamilyIcon';
import OrganicIcon from 'styleguide/components/Icons/AspectGroupIcons/OrganicIcon/OrganicIcon';
import PartyBbqIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyBbqIcon/PartyBbqIcon';
import PartyGraduationIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyGraduationIcon/PartyGraduationIcon';
import PartyHalloweenIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyHalloweenIcon/PartyHalloweenIcon';
import PartyHarvestIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyHarvestIcon/PartyHarvestIcon';
import PartyKidsIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyKidsIcon/PartyKidsIcon';
import PartyLabourDayIcon from 'styleguide/components/Icons/AspectGroupIcons/PartyLabourDayIcon/PartyLabourDayIcon';
import LogoIcon from 'styleguide/components/Logos/Logo/LogoIcon';
import UserIcon from 'styleguide/components/Icons/NormalIcons/UserIcon/UserIcon';
import SaladIcon from 'styleguide/components/Icons/AspectGroupIcons/SaladIcon/SaladIcon';
import AuthorFilterDialog from './filters/AuthorFilterDialog';
import RecipeProductFilterDialog from './filters/RecipeProductFilterDialog';
import { SearchFilterSection } from '../interfaces';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import { SearchFiltersContainer } from 'common/components/Search';
import RecipeCooperationIcon from '../../Recipe/components/RecipeCooperationIcon';
import { useIntl } from 'react-intl';
import { toggleMedia } from 'common/components/Media';
import styled from 'styled-components';
import { SearchFilterName } from '../types';
import CookingPreferenceDialog from './filters/CookingPreferenceDialog';

const FILTER_ICON_MAP = {
	bakingBread: <BakingBreadIcon />,
	bakingBuns: <BakingBunsIcon />,
	bakingCakes: <BakingCakesIcon />,
	bakingDough: <BakingDoughIcon />,
	bakingMuffins: <BakingMuffinsIcon />,
	bakingSavouryPastry: <BakingSavouryPastryIcon />,
	cheeseTray: <CheeseTrayIcon />,
	soup: <SoupIcon />,
	mifu: <MifuIcon />,
	dessert: <DessertIcon />,
	sweetPastry: <SweetPastryIcon />,
	meat: <MeatIcon />,
	fish: <FishIcon />,
	chicken: <ChickenIcon />,
	mifuRecipe: <MifuRecipeIcon />,
	vegeRecipe: <VegeRecipeIcon />,
	mincedMeat: <MincedMeatIcon />,
	dietDairyFree: <DietDairyFreeIcon />,
	dietEggFree: <DietEggFreeIcon />,
	dietLactoseFree: <DietLactoseFreeIcon />,
	dietLessSalt: <DietLessSaltIcon />,
	dietLowLactose: <DietLowLactoseIcon />,
	dietLight: <DietLightIcon />,
	dietProtein: <DietProteinIcon />,
	dietGlutenFree: <DietGlutenFreeIcon />,
	dietVegetarian: <DietVegetarianIcon />,
	dietLowCarb: <DietLowCarbIcon />,
	dietVegan: <VegeIcon />,
	everyDay: <EveryDayIcon />,
	family: <FamilyIcon />,
	organic: <OrganicIcon />,
	partyBbq: <PartyBbqIcon />,
	partyEaster: <PartyEasterIcon />,
	partyGraduation: <PartyGraduationIcon />,
	partyHalloween: <PartyHalloweenIcon />,
	partyHarvest: <PartyHarvestIcon />,
	partyKids: <PartyKidsIcon />,
	partyLabourDay: <PartyLabourDayIcon />,
	partyXmas: <PartyXmasIcon />,
	salad: <SaladIcon />,
	logo: <LogoIcon />,
	recipeCooperation: <RecipeCooperationIcon />,
	user: <UserIcon />,
	juice: <JuiceIcon />,
	pudding: <PuddingIcon />,
	activeSenior: <ActiveSeniorIcon />,
	yogurt: <YogurtIcon />,
};

const ResponsiveToggle = styled(Toggle)`
	${toggleMedia('flex', 'show', 'desktop')};
`;

interface RecipeSearchFiltersProps {
	filters: SearchFilterSection[];
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	onRadioFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
	professional: boolean;
}

const RecipeSearchFilters: React.FC<RecipeSearchFiltersProps> = ({
	filters,
	filterCount,
	selectedFilters,
	onFilterClick,
	onRadioFilterClick,
	isResultsLoading,
	professional,
}) => {
	const intl = useIntl();

	const simpleFilters = filters.filter(f => f.items);
	if (professional) {
		const recipeTypeFilter: SearchFilterSection = {
			type: 'Aspect',
			title: intl.formatMessage({ id: 'search_recipe_type' }),
			items: [
				{
					title: intl.formatMessage({ id: 'global_food_service' }),
					icon: '',
					imageUrl: null,
					name: SearchFilterName.context,
					value: 'foodservice',
					items: [],
				},
				{
					title: intl.formatMessage({ id: 'global_bakery_and_industry' }),
					icon: '',
					imageUrl: null,
					name: SearchFilterName.context,
					value: 'teollisuus',
					items: [],
				},
				{
					title: intl.formatMessage({ id: 'search_chef_of_the_year_cooperation' }),
					icon: '',
					imageUrl: null,
					name: SearchFilterName.themes,
					value: 'vuoden-kokki-ruokaohjeet',
					items: [],
				},
			],
			groups: null,
		};
		simpleFilters.splice(0, 0, recipeTypeFilter);
	}

	const onQuickFilterClick = (isActive: boolean) => {
		const quickFilter = simpleFilters.find(sf => sf.type === 'OnlyQuick');
		quickFilter && onFilterClick(quickFilter.slug!, quickFilter.value!, !isActive);
	};

	const onAspectToggleClick = (isActive: boolean) => {
		const filter = simpleFilters.find(sf => sf.type === 'AspectValueToggle');
		filter && onFilterClick(filter.slug!, filter.value!, !isActive);
	};

	return (
		<SearchFiltersContainer>
			{simpleFilters
				.filter(sf => sf.type === 'OnlyQuick')
				.map(({ title, slug }, index) => (
					<ResponsiveToggle
						className="toggle-filter"
						initialState={selectedFilters.find(f => f.name === slug) !== undefined}
						onToggleClick={onQuickFilterClick}
						size={'small'}
						key={index}>
						{title}
					</ResponsiveToggle>
				))}

			{simpleFilters
				.filter(sf => sf.type === 'ValioProduct')
				.map((_, index) => (
					<SearchFilter
						label={intl.formatMessage({ id: 'search_valio_product' })}
						modalTitle={intl.formatMessage({ id: 'search_find_recipe_by_valio_product' })}
						modalDesktopWidth="640px"
						key={index}>
						<RecipeProductFilterDialog onFilterClick={onRadioFilterClick} />
					</SearchFilter>
				))}

			{simpleFilters
				.filter(sf => sf.type === 'Aspect')
				.map(({ title, items }, index) => {
					const availableFilters = items?.filter(item => filterCount[item.value] > 0);
					return (
						<SearchFilter
							key={`${title}`}
							label={title}
							modalDesktopWidth="960px"
							isDisabled={availableFilters?.length === 0}>
							<SimpleFilterDialog
								key={index}
								filterItems={items!}
								filterCount={filterCount}
								filterIconMap={FILTER_ICON_MAP}
								selectedFilters={selectedFilters}
								onFilterClick={onFilterClick}
								isResultsLoading={isResultsLoading}
							/>
						</SearchFilter>
					);
				})}

			{simpleFilters
				.filter(sf => sf.type === 'CookingPreference')
				.map(({ title }, index) => (
					<SearchFilter key={index} label={title} modalDesktopWidth="600px">
						<CookingPreferenceDialog onFilterClick={onRadioFilterClick} selectedFilters={selectedFilters} />
					</SearchFilter>
				))}

			{simpleFilters
				.filter(sf => sf.type === 'Author')
				.map(({ title }, index) => (
					<SearchFilter key={index} label={title} modalDesktopWidth="600px">
						<AuthorFilterDialog onFilterClick={onRadioFilterClick} selectedFilters={selectedFilters} />
					</SearchFilter>
				))}

			{simpleFilters
				.filter(sf => sf.type === 'AspectValueToggle')
				.map(({ title, slug }, index) => (
					<Toggle
						className="toggle-filter"
						initialState={selectedFilters.find(f => f.name === slug) !== undefined}
						onToggleClick={onAspectToggleClick}
						size={'small'}
						key={index}>
						{title}
					</Toggle>
				))}
		</SearchFiltersContainer>
	);
};

export default RecipeSearchFilters;
