import * as React from 'react';
import { SearchFilterItem } from '../../interfaces';
import Checkbox from 'styleguide/components/Inputs/Checkbox/Checkbox';
import FilterCount from './FilterCount';

interface ProductGroupFilterItemProps extends SearchFilterItem {
	count?: number;
	selected?: boolean;
	disabled?: boolean;
	onFilterClick: (filterName: string, filterValue: string, selected: boolean, items: SearchFilterItem[]) => void;
}

const ProductGroupFilterItem: React.FC<ProductGroupFilterItemProps> = ({
	title,
	count,
	name,
	value,
	items,
	selected,
	disabled,
	onFilterClick,
}) => {
	const onChange = (event: React.FormEvent<HTMLInputElement>) => {
		if (!disabled) {
			onFilterClick(name, value, event.currentTarget.checked, items);
		}
	};

	return (
		<Checkbox
			name={name}
			id={`${name}-${value}`}
			value={value}
			checked={selected}
			disabled={disabled}
			onChange={onChange}>
			<FilterCount className="filter-count">{count}</FilterCount>
			{title}
		</Checkbox>
	);
};

export default ProductGroupFilterItem;
