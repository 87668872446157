import * as React from 'react';
import ProductGroupFilter from './ProductGroupFilter';
import { SearchFilterItem } from '../../interfaces';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import MilkIcon from 'styleguide/components/Icons/AspectGroupIcons/MilkIcon/MilkIcon';
import CreamIcon from 'styleguide/components/Icons/AspectGroupIcons/CreamIcon/CreamIcon';
import YogurtIcon from 'styleguide/components/Icons/AspectGroupIcons/YogurtIcon/YogurtIcon';
import CheeseIcon from 'styleguide/components/Icons/AspectGroupIcons/CheeseIcon/CheeseIcon';
import ButterIcon from 'styleguide/components/Icons/AspectGroupIcons/ButterIcon/ButterIcon';
import CurdIcon from 'styleguide/components/Icons/AspectGroupIcons/CurdIcon/CurdIcon';
import SouredWholeMilkIcon from 'styleguide/components/Icons/AspectGroupIcons/SouredWholeMilkIcon/SouredWholeMilkIcon';
import ButterMilkIcon from 'styleguide/components/Icons/AspectGroupIcons/ButterMilkIcon/ButterMilkIcon';
import JuiceIcon from 'styleguide/components/Icons/AspectGroupIcons/JuiceIcon/JuiceIcon';
import IceCreamIcon from 'styleguide/components/Icons/AspectGroupIcons/IceCreamIcon/IceCreamIcon';
import SoupIcon from 'styleguide/components/Icons/AspectGroupIcons/SoupIcon/SoupIcon';
import PuddingIcon from 'styleguide/components/Icons/AspectGroupIcons/PuddingIcon/PuddingIcon';
import VegeIcon from 'styleguide/components/Icons/AspectGroupIcons/VegeIcon/VegeIcon';
import MifuIcon from 'styleguide/components/Icons/AspectGroupIcons/MifuIcon/MifuIcon';
import PowderIcon from 'styleguide/components/Icons/AspectGroupIcons/PowderIcon/PowderIcon';
import ForKidsIcon from 'styleguide/components/Icons/AspectGroupIcons/ForKidsIcon/ForKidsIcon';
import RecipeIcon from 'styleguide/components/Icons/AspectGroupIcons/RecipeIcon/RecipeIcon';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { chunk } from 'utils/array';
import { isFilterSelected } from '../../utils';

const FILTER_ICON_MAP = {
	milk: <MilkIcon />,
	cream: <CreamIcon />,
	yogurt: <YogurtIcon />,
	cheese: <CheeseIcon />,
	butter: <ButterIcon />,
	curd: <CurdIcon />,
	souredWholeMilk: <SouredWholeMilkIcon />,
	butterMilk: <ButterMilkIcon />,
	juice: <JuiceIcon />,
	iceCream: <IceCreamIcon />,
	soup: <SoupIcon />,
	pudding: <PuddingIcon />,
	vege: <VegeIcon />,
	mifu: <MifuIcon />,
	powder: <PowderIcon />,
	forKids: <ForKidsIcon />,
	recipe: <RecipeIcon />,
};

const FILTER_OVERFLOW_PADDING = 30;

const FiltersContainer = styled.div`
	${media.desktop`
		display: flex;
		max-height: 500px;
		overflow: auto;
		padding: 0 30px;
		position: relative;
	`};
`;

const FilterShadow = styled.div`
	display: none;

	${media.desktop`
		display: block;
		height: 10px;
  	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
		position: absolute;
    width: 100%;
    left: 0;
    bottom: 110px;
	`};
`;

const FilterGroup = styled.div`
	${media.desktop`
		width: 33.33%;
	`};

	& + & {
		margin-top: 6px;

		${media.desktop`
			margin-top: 0;
			margin-left: 6px;
		`};
	}

	.product-group-filter + .product-group-filter {
		margin-top: 6px;
	}
`;

const FilterGroupContainer = styled.div<ProductGroupFilterDialogState>`
	padding-bottom: ${props => (props.hasContainerOverFlow ? `${FILTER_OVERFLOW_PADDING}px` : '0')};
`;

interface ProductGroupFilterDialogProps {
	filterItems: SearchFilterItem[];
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	isResultsLoading?: boolean;
	onFilterClick: (filterName: string, filterValue: string, selected: boolean, items: SearchFilterItem[]) => void;
	onRadioFilterClick: (filterName: string, filterValue: string, selected: boolean, items: SearchFilterItem[]) => void;
}

interface ProductGroupFilterDialogState {
	hasContainerOverFlow: boolean;
}

class ProductGroupFilterDialog extends React.Component<ProductGroupFilterDialogProps, ProductGroupFilterDialogState> {
	public containerRef: React.RefObject<any>;

	constructor(props: ProductGroupFilterDialogProps) {
		super(props);

		this.state = {
			hasContainerOverFlow: false,
		};

		this.containerRef = React.createRef();
	}

	public render() {
		const { filterItems, filterCount, selectedFilters, isResultsLoading, onFilterClick } = this.props;
		const { hasContainerOverFlow } = this.state;

		const filterGroups = chunk(filterItems, 3);

		return (
			<>
				<FiltersContainer ref={this.containerRef}>
					{filterGroups.map((groupedFilterItems, index) => (
						<FilterGroup key={`groupedFilterItems-${index}`}>
							<FilterGroupContainer hasContainerOverFlow={hasContainerOverFlow}>
								{groupedFilterItems.map(groupedFilterItem => {
									const { title, name, value, icon, items } = groupedFilterItem;
									const count = filterCount[value] || 0;
									const selected = isFilterSelected(groupedFilterItem, selectedFilters);

									return (
										<ProductGroupFilter
											key={value}
											filterName={name}
											filterValue={value}
											title={title}
											count={count}
											items={items}
											className="product-group-filter"
											filterCount={filterCount}
											selectedFilters={selectedFilters}
											icon={icon && FILTER_ICON_MAP[icon]}
											selected={selected}
											disabled={count === 0 || (!selected && !!isResultsLoading)}
											isResultsLoading={isResultsLoading}
											onFilterClick={onFilterClick}
											onFilterOpen={this.setFilterShadowVisibility}
										/>
									);
								})}
							</FilterGroupContainer>
						</FilterGroup>
					))}
				</FiltersContainer>
				{hasContainerOverFlow && <FilterShadow />}
			</>
		);
	}

	private setFilterShadowVisibility = () => {
		const container = this.containerRef.current;
		if (container) {
			this.setState({
				hasContainerOverFlow: container.scrollHeight - FILTER_OVERFLOW_PADDING > container.offsetHeight,
			});
		}
	};
}

export default ProductGroupFilterDialog;
