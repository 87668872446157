import { withWindow } from '../styleguide/helpers';
import { parseQuery, replaceToSearch } from './query-string';
import { getHashString, setHashString } from './hash-string';
import { SEARCH_HASH_SCROLL_PARAMETER } from '../pagetypes/Search/constants';

export function saveScrollPositionToHash() {
	withWindow(window => {
		const top = window.pageYOffset || window.document.documentElement.scrollTop;
		const newHashString = replaceToSearch(getHashString(), SEARCH_HASH_SCROLL_PARAMETER, Math.round(top).toString());
		setHashString(newHashString);
	});
}

export function resumeScrollPositionFromHash() {
	withWindow(window => {
		const top = Number(parseQuery(getHashString())[SEARCH_HASH_SCROLL_PARAMETER]) || 0;
		setTimeout(() => window.scrollTo(0, top), 0);
	});
}
