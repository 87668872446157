import { withWindow } from '../styleguide/helpers';
import { parseQuery, replaceToSearch } from './query-string';
import { SEARCH_HASH_PAGE_PARAMETER } from '../pagetypes/Search/constants';

export function getHashString() {
	return withWindow(w => {
		const hashString = w.location.hash;
		return hashString.length < 1 ? '' : hashString.slice(1);
	});
}

export function setHashString(newHashString: string) {
	return withWindow(w => {
		w.location.hash = `#${newHashString}`;
	});
}

export function clearHashString() {
	return withWindow(w => {
		w.location.hash = '';
	});
}

export function getHashSearchPageOrDefault() {
	return Number(parseQuery(getHashString())[SEARCH_HASH_PAGE_PARAMETER]) || 1;
}

export function setHashSearchPage(pageNumber: number) {
	const newHashString = replaceToSearch(getHashString(), SEARCH_HASH_PAGE_PARAMETER, pageNumber.toString());
	setHashString(newHashString);
}
