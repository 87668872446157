import * as React from 'react';
import { Component } from 'react';
import styled, { css } from 'styled-components';
import { media, mediaBreakpoints } from '../../../helpers';
import { ProductCardInterface } from '../../../interfaces';
import { LazyImage } from '../../../components/LazyImage';
import { CardTitle, CardWrapper } from '../Card';
import NoveltyIcon from '../../Icons/AspectGroupIcons/NoveltyIcon';
import { CardWrapperProps } from '../Card/CardWrapper';
import Image from '../../PicturesAndVideos/Image';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';

const BORDER_RADIUS = 3; // px

const ProductCardWrapper = styled(CardWrapper)<
	ProductCardProps & CardWrapperProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>`
	max-width: ${({ size }) => (size === 'small' ? '174px' : 'none')};
`;

const ProductCardImageWrapper = styled('div')<ProductCardProps>`
	border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
	text-align: center;
	margin: 0 auto;
	position: relative;

	${({ theme, size }) => theme.styledVariations.productCardImage(size)};

	& > img {
		width: 100%;
		height: 100%;
	}

	> .novelty-icon {
		position: absolute;

		${props => {
			if (props.size === 'medium' || props.size === 'large') {
				return css`
					left: -30px;
					bottom: -10px;
					width: 80px;
					height: 80px;
				`;
			}
			return css`
				left: -20px;
				bottom: -5px;
				width: 60px;
				height: 60px;
			`;
		}};
	}
`;

const StyledProductCardTitle = styled(CardTitle)<{ useLargeTextSize?: boolean }>`
	text-align: center;
	position: relative;
	overflow: hidden;
	word-break: unset;
	background: transparent;

	${({ useLargeTextSize }) => {
		if (useLargeTextSize) {
			return css`
				font-size: 16px;

				${media.phone`
					font-size: 18px;
				`};
			`;
		}
		return null;
	}};

	${media.phone`
		justify-content: flex-start;
	`};
`;

export type ProductCardSizes = 'small' | 'medium' | 'large';

interface ProductCardProps {
	size?: ProductCardSizes;
	useLazyImage?: boolean;
	useImagePresets?: boolean;
	useLargeTextSize?: boolean;
	language?: string | null;
}

type Props = ProductCardProps & ProductCardInterface & React.AnchorHTMLAttributes<HTMLAnchorElement>;

class ProductCard extends Component<Props> {
	public static defaultProps = {
		useLazyImage: true,
		size: 'small',
	};

	public render() {
		const { size, url, imageUrl, productName, className, isNovelty, useLargeTextSize, language, ...rest } = this.props;
		return (
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<ProductCardWrapper {...rest} href={url} size={size} className={`product-card ${className ? className : ''}`}>
						<ProductCardImageWrapper className="product-card__image-wrapper" size={size}>
							{this.renderImage()}
							{!!isNovelty && <NoveltyIcon className="novelty-icon" language={language} forProduct={true} />}
						</ProductCardImageWrapper>
						<StyledProductCardTitle
							useLargeTextSize={useLargeTextSize}
							className="product-card__name"
							color={contentTheme.color}>
							{productName}
						</StyledProductCardTitle>
					</ProductCardWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	}

	private renderImage() {
		const { imageUrl, imagePresetSuffix, productName, useImagePresets, size, useLazyImage } = this.props;
		if (!useImagePresets || typeof imageUrl !== 'string') {
			if (useLazyImage) {
				return <LazyImage src={imageUrl} alt={productName} />;
			}
			return <Image src={imageUrl} alt={productName} />;
		}

		const suffix = imagePresetSuffix ? `-${imagePresetSuffix}` : '';
		let srcSet = [
			`${imageUrl}/100x100${suffix} 1x`,
			`${imageUrl}/140x140${suffix} 1.5x`,
			`${imageUrl}/200x200${suffix} 2x`,
		];
		let sizes;

		if (size === 'medium' || size === 'large') {
			srcSet = [
				`${imageUrl}/100x100${suffix} 100w`,
				`${imageUrl}/140x140${suffix} 140w`,
				`${imageUrl}/200x200${suffix} 200w`,
				`${imageUrl}/200x200${suffix} 2x`,
			];
		}

		if (size === 'medium') {
			sizes = [
				`(max-width: ${mediaBreakpoints.desktop - 1}) 100px`,
				`(min-width: ${mediaBreakpoints.desktop}) 140px`,
				'140px',
			];
		}

		if (size === 'large') {
			sizes = [
				`(max-width: ${mediaBreakpoints.tablet - 1}) 100px`,
				`(min-width: ${mediaBreakpoints.tablet}) 140px`,
				`(min-width: ${mediaBreakpoints.desktop}) 200px`,
				'200px',
			];
		}

		return (
			<LazyImage src={imageUrl}>
				<img
					srcSet={srcSet.join(',')}
					sizes={sizes && sizes.join(',')}
					src={`${imageUrl}${this.getImagePreset()}`}
					alt={productName}
				/>
			</LazyImage>
		);
	}

	private getImagePreset() {
		const { imagePresetSuffix, size, useImagePresets } = this.props;

		if (!useImagePresets) {
			return '';
		}

		const suffix = imagePresetSuffix ? `-${imagePresetSuffix}` : '';

		let preset = '100x100';
		if (size === 'medium') {
			preset = '140x140';
		}
		if (size === 'large') {
			preset = '200x200';
		}

		return `/${preset}${suffix}`;
	}
}

export default ProductCard;
