import * as React from 'react';
import IconFilter from './IconFilter';
import styled from 'styled-components';
import { NameAndValue } from 'common/interfaces/common';
import { useIntl } from 'react-intl';
import { HandIcon, IndustrialIcon, TimeIcon } from 'styleguide/components/Icons';
import { SearchFilterName } from 'pagetypes/Search/types';

const IconFilterGrid = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin: 0 -6px;

	.icon-filter {
		flex: 1 1 159px;
		margin: 0 6px;

		> img {
			width: 64px;
			margin: 25px 0;
		}

		> svg {
			margin: 26px 0;
		}
	}
`;

const HandIconAdjustedFilter = styled(IconFilter)`
	&.icon-filter > img {
		width: 75px;
		height: 92px;
		margin: 0;
	}
`;

const IndustrialIconAdjustedFilter = styled(IconFilter)`
	&.icon-filter > img {
		width: 83px;
		height: 83px;
		margin: 4px 0 4px;
	}
`;

enum PreferenceFilterTypes {
	Easy = '1',
	Quick = '40',
	Challenge = '2,3',
}

export interface CookingPreferenceDialogProps {
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

const CookingPreferenceDialog: React.FC<CookingPreferenceDialogProps> = ({ selectedFilters, onFilterClick }) => {
	const intl = useIntl();

	const onClick = (filterName: string, filterValue: string, selected: boolean) => {
		onFilterClick(filterName, filterValue, selected);
	};

	return (
		<IconFilterGrid>
			<HandIconAdjustedFilter
				key="helpot"
				filterName={SearchFilterName.difficulty}
				filterValue={PreferenceFilterTypes.Easy}
				title={intl.formatMessage({ id: 'search_filter_preference_easy' })}
				icon={<HandIcon />}
				onFilterClick={onClick}
				selected={selectedFilters.some(sf => sf.value === PreferenceFilterTypes.Easy)}
				className="icon-filter"
			/>
			<IconFilter
				key="valmistusaikaMax"
				filterName={SearchFilterName.maxTime}
				filterValue={PreferenceFilterTypes.Quick}
				title={intl.formatMessage({ id: 'search_recipe_type_quick' })}
				icon={<TimeIcon size={40} />}
				onFilterClick={onClick}
				selected={selectedFilters.some(sf => sf.value === PreferenceFilterTypes.Quick)}
			/>
			<IndustrialIconAdjustedFilter
				key="vaikea"
				filterName={SearchFilterName.difficulty}
				filterValue={PreferenceFilterTypes.Challenge}
				title={intl.formatMessage({ id: 'search_filter_preference_hard' })}
				icon={<IndustrialIcon />}
				onFilterClick={onClick}
				selected={selectedFilters.some(sf => sf.value === PreferenceFilterTypes.Challenge)}
				className="icon-filter"
			/>
		</IconFilterGrid>
	);
};

export default CookingPreferenceDialog;
