import * as React from 'react';
import { GenericHighlightWidgetProps } from './interfaces/GenericHighlight';
import GenericHighlightCard from '../../../styleguide/components/Cards/GenericHighlightCard';
import styled from 'styled-components';
import { WIDGET_SPACING_LARGE } from './constants';
import { media } from '../../../styleguide/helpers';

export default function GenericHighlight({ widgetId, title, items }: GenericHighlightWidgetProps) {
	return (
		<div id={widgetId}>
			<GenericHighlightTitle>{title}</GenericHighlightTitle>
			<GenericHighlightCards items={items} />
		</div>
	);
}

function GenericHighlightCards({ items }: Pick<GenericHighlightWidgetProps, 'items'>) {
	return (
		<GenericHighlightContainer>
			{items.map((item, index) => (
				<GenericHighlightCard {...item} key={index} />
			))}
		</GenericHighlightContainer>
	);
}

const GenericHighlightTitle = styled.h2`
	${({ theme }) => theme.typography.heading};
	color: ${({ theme }) => theme.colors.heading};
	font-size: 32px;
	line-height: 1.25;
	text-align: center;
`;

interface GenericHighlightContainerProps {
	isLeftAligned?: boolean;
}

export const GenericHighlightContainer = styled.div<GenericHighlightContainerProps>`
	margin: ${WIDGET_SPACING_LARGE}px auto;

	${media.tablet<GenericHighlightContainerProps>`
		display: flex;
		flex-wrap: wrap;
		margin: ${WIDGET_SPACING_LARGE}px -10px;
		justify-content: ${props => (props.isLeftAligned ? 'flex-start' : 'center')};

		/* IE11 */
		*::-ms-backdrop, & {
			display: block;
			overflow: hidden;
		}
	`};

	${media.desktop1440`
		margin: ${WIDGET_SPACING_LARGE}px -20px;
	`};
`;
