import * as React from 'react';
import throttle from 'lodash/throttle';
import { ArticleHighlightConfiguration } from './interfaces/ArticleHighlight';
import { mediaBreakpoints } from 'styleguide/helpers/media';
import { withWindow } from 'styleguide/helpers/window';
import ArticleCard from 'styleguide/components/Cards/ArticleCard/ArticleCard';
import { WidgetCommonProps } from './interfaces/interfaces';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { GenericHighlightCardContainerLayout } from '../../../styleguide/components/Cards/GenericHighlightCard/GenericHighlightCard';
import { GenericHighlightContainer } from './GenericHighlight';

export interface ArticleHighlightProps {
	isLeftAligned?: boolean;
	isLoading?: boolean;
	onArticleClick?: () => void;
	onMount?: () => void;
}

interface ArticleHighlightState {
	truncateLines: number;
}

interface DefaultProps {
	layoutName: GenericHighlightCardContainerLayout;
}

class ArticleHighlight extends React.Component<
	ArticleHighlightProps & WidgetCommonProps & ArticleHighlightConfiguration & InjectedRoutableProps,
	ArticleHighlightState
> {
	public static defaultProps: DefaultProps = {
		layoutName: 'two-column',
	};

	public state: ArticleHighlightState = {
		truncateLines: this.getTruncateLength(),
	};

	private onResize = throttle(() => this.setState({ truncateLines: this.getTruncateLength() }), 250);

	public componentDidMount() {
		if (this.props.onMount) {
			this.props.onMount();
		}
		withWindow(w => {
			w.addEventListener('load', this.onResize);
			w.addEventListener('resize', this.onResize);
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('load', this.onResize);
			w.removeEventListener('resize', this.onResize);
		});
	}

	public render() {
		const { widgetId, articles, showAuthor, layoutName, isLoading, isLeftAligned, onLinkClick } = this.props;
		const onArticleCLick: InjectedRoutableProps['onLinkClick'] = event => {
			if (this.props.onArticleClick) {
				this.props.onArticleClick();
			}
			onLinkClick(event);
		};

		return (
			<GenericHighlightContainer
				id={widgetId}
				isLeftAligned={isLeftAligned && articles.length > 1}
				className="article-highlight-wrapper">
				{articles.map(article => (
					<ArticleCard
						key={article.url}
						{...article}
						onClick={onArticleCLick}
						layoutName={layoutName}
						truncateLength={this.state.truncateLines}
						showAuthor={showAuthor}
					/>
				))}
				{isLoading && <LoadingSpinner className="article-search-spinner" />}
			</GenericHighlightContainer>
		);
	}

	private getTruncateLength(): number {
		const { showAuthor } = this.props;

		return withWindow(w => {
			if (showAuthor) {
				if (w.innerWidth < mediaBreakpoints.phone) {
					return 35;
				}
				return 65;
			}

			if (w.innerWidth < mediaBreakpoints.desktop) {
				return 75;
			}

			return 85;
		});
	}
}

export default injectRoutable(ArticleHighlight);
