import * as React from 'react';
import IconFilter from './IconFilter';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { SearchFilterItem } from '../../interfaces';
import { NumberMap, NameAndValue, IconMap } from 'common/interfaces/common';
import { isFilterSelected } from '../../utils';
import PillFilters from './PillFilters';

function getIconFilterWidth(count: number) {
	if (count > 5 && count < 8) {
		return 25;
	}
	return 20;
}

interface IconFilterProps {
	count: number;
}

const IconFilterGrid = styled.div<IconFilterProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: -6px;
	flex-wrap: wrap;

	${media.desktop`
		justify-content: center;
		align-items: stretch;
	`};

	.icon-filter {
		flex: 0 1 calc(50% - 12px);
		margin: 6px;

		${media.tablet`
			flex: 0 1 calc(100% / 3 - 12px);
		`};

		${media.desktop<IconFilterProps>`
			flex: 0 1 calc(${({ count }) => getIconFilterWidth(count)}% - 12px);

			/* IE11 */
			*::-ms-backdrop, & {
				flex: 0 1 162px;
			}
		`};
	}
`;

const PillFilterWrapper = styled.div`
	margin: 27px 0;
	text-align: center;

	${media.desktop`
		margin: 27px 0 -3px;
	`};

	& > button {
		margin: 3px;
	}
`;

interface SimpleFilterDialogProps {
	filterItems: SearchFilterItem[];
	filterCount: NumberMap;
	filterIconMap?: IconMap;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
}

const SimpleFilterDialog: React.FC<SimpleFilterDialogProps> = ({
	filterItems,
	filterCount,
	filterIconMap,
	selectedFilters,
	onFilterClick,
	isResultsLoading,
}) => {
	const iconFilters: SearchFilterItem[] = [];
	const pillFilters: SearchFilterItem[] = [];

	filterItems.forEach(filter => {
		if (filter.icon && filterIconMap && filterIconMap[filter.icon]) {
			iconFilters.push(filter);
		} else {
			pillFilters.push(filter);
		}
	});

	return (
		<>
			{iconFilters.length > 0 && (
				<IconFilterGrid count={iconFilters.length}>
					{iconFilters.map(iconFilter => {
						const { title, name, value, icon } = iconFilter;
						const count = filterCount[value] || 0;
						const selected = isFilterSelected(iconFilter, selectedFilters);
						return (
							icon &&
							filterIconMap &&
							filterIconMap[icon] && (
								<IconFilter
									key={value}
									filterName={name}
									filterValue={value}
									title={title}
									count={count}
									icon={filterIconMap[icon]}
									onFilterClick={onFilterClick}
									selected={selected}
									disabled={count === 0 || (!selected && !!isResultsLoading)}
								/>
							)
						);
					})}
				</IconFilterGrid>
			)}
			{pillFilters.length > 0 && (
				<PillFilterWrapper>
					<PillFilters
						filters={pillFilters}
						filterCount={filterCount}
						selectedFilters={selectedFilters}
						isResultsLoading={isResultsLoading}
						onFilterClick={onFilterClick}
					/>
				</PillFilterWrapper>
			)}
		</>
	);
};

export default SimpleFilterDialog;
