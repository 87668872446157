import * as React from 'react';
import { NewsIcon } from 'styleguide/components/Icons';
import { getFormattedFullDate } from 'utils/date';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import { AuthorTagStyleProps, AuthorTagWrapper, AuthoredDate } from '../AuthorTag/AuthorTagWrapper';
import { useIntl } from 'react-intl';

const IconWrapper = styled.div<AuthorTagStyleProps>`
	border-radius: 50%;
	width: 32px;
	height: 32px;
	flex: 0 0 32px;
	background: ${({ theme }) => theme.colors.brandPrimaryFeatureBackground};
	margin-right: ${({ theme }) => theme.grid.gutterInPx()};

	${media.desktop<AuthorTagStyleProps>`
		${({ size, theme }) => {
			if (size !== 'small') {
				return css`
					width: 64px;
					height: 64px;
					flex: 0 0 64px;
					margin-right: ${theme.grid.gutterInPx(2)};
				`;
			}
			return null;
		}}
  `};

	img {
		width: 100%;
		height: 100%;
	}
`;

interface Props extends AuthorTagStyleProps {
	className?: string;
	date: string;
}

const NewsTag: React.FC<Props> = ({ date, className, size }) => {
	const intl = useIntl();
	const formattedDate = getFormattedFullDate(intl, new Date(date));

	return (
		<AuthorTagWrapper className={className} size={size}>
			<IconWrapper size={size}>
				<NewsIcon />
			</IconWrapper>
			<AuthoredDate dateTime={date} size={size}>
				{formattedDate}
			</AuthoredDate>
		</AuthorTagWrapper>
	);
};

export default NewsTag;
