import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import ContentTag, { ContentTagProps, getContentTagHeight } from 'styleguide/components/Tags/ContentTag/ContentTag';
import { GenericHighlightWidgetItem } from '../../../../common/components/Widgets/interfaces/GenericHighlight';

export type GenericHighlightCardContainerLayout = 'two-column' | 'three-column';
export type GenericHighlightCardImageSize = 'small';

export interface GenericHighlightCardContainerProps {
	layoutName?: GenericHighlightCardContainerLayout;
	imageSize?: GenericHighlightCardImageSize;
}

function RenderGenericHighlightCardContainer({
	href,
	layoutName,
	children,
}: React.PropsWithChildren<{ href: string; layoutName?: GenericHighlightCardContainerLayout }>) {
	return href ? (
		<GenericHighlightCardContainer href={href} layoutName={layoutName}>
			{children}
		</GenericHighlightCardContainer>
	) : (
		<GenericHighlightCardWrapper layoutName={layoutName}>{children}</GenericHighlightCardWrapper>
	);
}

export default function GenericHighlightCard({
	alt,
	contentTagColor,
	contentTagText,
	description,
	imageUrl,
	linkUrl,
	title,
}: GenericHighlightWidgetItem) {
	const layoutName = 'two-column';
	const isContentTagHidden = contentTagText === undefined || contentTagText === null;
	return (
		<RenderGenericHighlightCardContainer href={linkUrl} layoutName={layoutName}>
			<GenericHighlightCardImageContainer layoutName={layoutName}>
				<GenericHighlightCardImageBorder>
					<img src={imageUrl} alt={alt} />
				</GenericHighlightCardImageBorder>
			</GenericHighlightCardImageContainer>
			<GenericHighlightCardContent layoutName={layoutName}>
				<GenericHighlightCardContentTag color={contentTagColor || undefined} isHidden={isContentTagHidden}>
					{contentTagText}
				</GenericHighlightCardContentTag>
				<GenericHighlightCardTitle isContentTagHidden={isContentTagHidden}>{title}</GenericHighlightCardTitle>
				<GenericHighlightCardDescription>{description}</GenericHighlightCardDescription>
			</GenericHighlightCardContent>
		</RenderGenericHighlightCardContainer>
	);
}

export const GenericHighlightCardContainer = styled.a<GenericHighlightCardContainerProps>`
	display: flex;
	margin: 10px 0;
	color: ${props => props.theme.colors.bodyText};
	text-decoration: none;

	${media.tablet`
		flex: 1 1 calc(50% - 20px);
		margin: 10px;
		min-width: 0;

		/* IE11 */
		*::-ms-backdrop, & {
			width: calc(50% - 20px);
			float: left;
		}
	`};

	${media.desktop<GenericHighlightCardContainerProps>`
		margin: 20px;

		${props => {
			const divider = props.layoutName === 'three-column' ? 3 : 2;
			return css`
				flex: 0 0 calc(100% / ${divider} - 40px);
				width: calc(100% / ${divider} - 40px);

				/* IE11 */
				*::-ms-backdrop,
				& {
					width: calc(100% / ${divider} - 41px);
				}
			`;
		}};
	`};
`;

const GenericHighlightCardWrapper = styled.div<GenericHighlightCardContainerProps>`
	display: flex;
	margin: 10px 0;
	color: ${props => props.theme.colors.bodyText};
	text-decoration: none;

	${media.tablet`
		flex: 1 1 calc(50% - 20px);
		margin: 10px;
		min-width: 0;

		/* IE11 */
		*::-ms-backdrop, & {
			width: calc(50% - 20px);
			float: left;
		}
	`};

	${media.desktop<GenericHighlightCardContainerProps>`
		margin: 20px;

		${props => {
			const divider = props.layoutName === 'three-column' ? 3 : 2;
			return css`
				flex: 0 0 calc(100% / ${divider} - 40px);
				width: calc(100% / ${divider} - 40px);

				/* IE11 */
				*::-ms-backdrop,
				& {
					width: calc(100% / ${divider} - 41px);
				}
			`;
		}};
	`};
`;

export const GenericHighlightCardImageContainer = styled.div<GenericHighlightCardContainerProps>`
	background-color: ${props => props.theme.colors.imagePlaceHolder};
	flex: 1 0 45%;
	width: 45%;
	min-height: 180px;
	img {
		max-height: calc(180px - ${({ theme }) => theme.grid.gutterInPx(4)});
	}

	${media.desktop<GenericHighlightCardContainerProps>`
		flex: 1 0 50%;
		width: 50%;
		min-height: ${props => (props.imageSize === 'small' || props.layoutName === 'three-column' ? 244 : 300)}px;
		
		img {
			max-height: calc(${props => (props.imageSize === 'small' || props.layoutName === 'three-column' ? 244 : 300)}px - ${({
		theme,
	}) => theme.grid.gutterInPx(6)});
		}
	`};

	img {
		max-width: 100%;
		height: auto;
	}
`;

export const GenericHighlightCardImageBorder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.desktop<GenericHighlightCardContainerProps>`
		padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	`};
`;

export const GenericHighlightCardContent = styled.div<GenericHighlightCardContainerProps>`
	padding: 0 15px 5px;
	position: relative;
	flex: 1 0 55%;
	width: 55%;

	${media.desktop<GenericHighlightCardContainerProps>`
		flex: 1 0 50%;
		width: 50%;
		padding: ${props => (props.layoutName === 'two-column' ? '0 30px 20px' : '0 15px 5px')};
	`};

	${media.desktop1440`
		flex: 0 1 50%;
		width: 50%;

		/* IE11 */
		*::-ms-backdrop, & {
			flex: 1 0 50%;
			width: 50%;
		}

	`};
`;

interface GenericHighlightCardContentTagProps extends ContentTagProps {
	isHidden?: boolean;
}

export const GenericHighlightCardContentTag = styled(ContentTag)<GenericHighlightCardContentTagProps>`
	position: relative;
	left: -60px;
	margin: 20px 0;
	display: ${props => (props.isHidden ? 'none' : 'inline-block')};

	${media.desktop<ContentTagProps>`
		left: -${props => (props.size === 'small' ? 60 : 70)}px;
		margin: ${props => (props.size === 'small' ? 20 : 30)}px 0;
	`};
`;

interface GenericHighlightCardTitleProps extends ContentTagProps {
	isContentTagHidden: boolean;
}

const getHighlightCardTitleMargin = (isContentTagHidden: boolean, size?: 'small' | 'large', isMobile?: boolean) => {
	if (!isContentTagHidden) {
		return -10;
	}
	const contentTagHeight = Number(getContentTagHeight(size, isMobile).replace('px', ''));
	return contentTagHeight + 40;
};

const getHighlightCardTitleDesktopMargin = (
	isContentTagHidden: boolean,
	size?: 'small' | 'large',
	isMobile?: boolean
) => {
	if (!isContentTagHidden) {
		return -10;
	}

	const contentTagHeight = Number(getContentTagHeight(size, isMobile).replace('px', ''));
	return contentTagHeight + (size === 'small' ? 40 : 60);
};

const GenericHighlightCardTitle = styled.div<GenericHighlightCardTitleProps>`
	${({ theme }) => theme.typography.heading};
	color: ${({ theme }) => theme.colors.heading};
	margin-top: ${props =>
		getHighlightCardTitleMargin(props.isContentTagHidden, props.size, !Boolean(props.disableMobileSize))}px;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	${media.desktop<GenericHighlightCardTitleProps>`
			margin-top: ${props =>
				getHighlightCardTitleDesktopMargin(props.isContentTagHidden, props.size, !Boolean(props.disableMobileSize))}px;
	`};
`;

const GenericHighlightCardDescription = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	font-family: ${({ theme }) => theme.fonts.secondary};
	color: ${({ theme }) => theme.colors.bodyText};
	font-size: 14px;
	line-height: 1.43;
`;

export function truncate(str: string, maxLength: number) {
	if (str.length > maxLength) {
		return `${str.slice(0, maxLength).replace(/(\&|\&s|\&sh|\&shy|\&shy;)$/, '')}&hellip;`;
	}
	return str;
}
