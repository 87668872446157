import * as React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { FrontHeader, ContentWrapper, FrontTitle } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import { media } from 'styleguide/helpers/media';
import { InputSize } from 'styleguide/types/inputTypes';
import styled from 'styled-components';
import { getHeroMediaSource } from 'utils/media';
import SearchResults from 'common/components/Navigation/components/SearchResults/SearchResults';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ContentCard from 'common/components/General/ContentCard';
import { SearchType } from 'pagetypes/Search/types';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import SearchArticles from './components/SearchArticles';
import SearchRecipes from './components/SearchRecipes';
import SearchProducts from './components/SearchProducts';
import { State } from 'common/reducers';
import { searchActions } from './reducers/search-actions';
import { SearchState, ResourceAspectFilter } from './interfaces';
import { routingActions, RoutingState } from 'common/components/Routing/reducers';
import { replaceToSearch, addToSearch } from 'utils/query-string';
import { Helmet } from 'react-helmet';
import BackToTop from './components/BackToTop';
import { getSettingValue, getSiteUrlPrefixOrDefault } from 'common/components/App/services';
import { pushOtherPageLoad } from 'utils/dataLayer';
import { SEARCH_API_URL_SEARCH_QUERY } from './constants';
import WidgetZone from 'common/components/Widgets/WidgetZone';

const SearchContentWrapper = styled(ContentWrapper)`
	position: relative;
	padding-bottom: 60px;
	overflow: hidden;
`;

const BackToTopWrapper = styled.div`
	display: block;

	${media.desktop`
    display: none;
  `};
`;

type Props = SearchStateProps & SearchDispatchProps & WrappedComponentProps & InjectedRoutableProps;

class Search extends React.Component<Props> {
	private setSearchTextTimeout: NodeJS.Timer;

	public componentDidMount() {
		pushOtherPageLoad();
	}

	public render() {
		const {
			searchType,
			routingState,
			title,
			heroImageDesktop,
			heroImageMobile,
			heroImageOffsetY,
			heroMediaType,
			canonicalUrl,
			professional,
			siteUrlPrefix,
			searchQuery,
		} = this.props;

		const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'recipe-front-hero');

		const hakuValue = routingState.query && routingState.query[searchQuery];
		const initialSearchTerm = Array.isArray(hakuValue) ? hakuValue[0] : hakuValue;

		return (
			<>
				{canonicalUrl && (
					<Helmet>
						<link rel="canonical" href={canonicalUrl} />
					</Helmet>
				)}
				<Hero
					className="search-hero"
					src={heroMedia.mediaSrc}
					mediaType={heroMedia.mediaType}
					offsetY={heroImageOffsetY}
					size="medium">
					<FrontHeader>
						<FrontTitle>{title}</FrontTitle>
						<SearchResults
							id={`${searchType}-search`}
							placeholder={this.props.intl.formatMessage({ id: this.getSearchPlaceholder(searchType) })}
							showMostPopularKeywords={false}
							mostPopularKeywords={[]}
							onSetSearchText={this.onSetSearchText}
							onClear={this.onSearchClear}
							inputSize={{ default: InputSize.medium, tablet: InputSize.large, desktop: InputSize.large }}
							disableSuggestions={true}
							className="search-results"
							initialSearchTerm={initialSearchTerm}
							professional={professional}
							siteUrlPrefix={siteUrlPrefix}
						/>
					</FrontHeader>
				</Hero>

				<ContentCard />

				<WidgetZone name="Header" isFullWidth={true} />
				<WidgetZone name="Main" isFullWidth={true} />

				<SearchContentWrapper>{this.renderPageContents(searchType)}</SearchContentWrapper>

				<BackToTopWrapper>
					<BackToTop />
				</BackToTopWrapper>
			</>
		);
	}

	private onSetSearchText = (input: string) => {
		if (this.setSearchTextTimeout) {
			clearTimeout(this.setSearchTextTimeout);
		}

		this.setSearchTextTimeout = setTimeout(() => {
			const { updateSearch, routingState, searchType, aspectFilters } = this.props;
			let search = routingState.search || '';

			if (searchType === SearchType.RECIPE && !!aspectFilters) {
				const splittedInput = input.toLowerCase().split(' ');

				aspectFilters
					.filter(af => splittedInput.some(si => af.word === si))
					.forEach(av => {
						input = input.replace(av.word, '').trim();
						search = addToSearch(search, av.aspectTitle, av.slug);
					});
			}

			search = replaceToSearch(search, this.props.searchQuery, encodeURIComponent(input));
			updateSearch(search.toLowerCase());
		}, 250);
	};

	private onSearchClear = () => {
		const { updateSearch, routingState } = this.props;
		const search = replaceToSearch(routingState.search || '', this.props.searchQuery);
		updateSearch(search);
	};

	private getSearchPlaceholder = (searchType: SearchType) => {
		switch (searchType) {
			case SearchType.RECIPE:
				return 'search_recipe_front_search_placeholder';
			case SearchType.PRODUCT:
				return 'search_product_front_search_placeholder';
			case SearchType.ARTICLE:
				return 'search_article_search_placeholder';
			default:
				return 'search_search_page_search_placeholder';
		}
	};

	private renderPageContents = (searchType: SearchType) => {
		if (searchType === SearchType.RECIPE) {
			return <SearchRecipes />;
		} else if (searchType === SearchType.PRODUCT) {
			return <SearchProducts />;
		} else if (searchType === SearchType.ARTICLE) {
			return <SearchArticles />;
		}

		return null;
	};
}

interface SearchStateProps {
	query: string;
	searchType: SearchType;
	routingState: RoutingState;
	title?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	canonicalUrl?: string;
	professional: boolean;
	aspectFilters?: ResourceAspectFilter[];
	siteUrlPrefix: string;
	searchQuery: string;
}

const mapStateToProps: MapStateToProps<SearchStateProps, {}, State> = (state: State) => {
	const { resource, routing, app } = state;
	const { query, searchType, isProfessionalSearch, aspectFilters } = resource.content as SearchState;
	const { title, heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType, canonicalUrl } = resource;
	const sites = (app.settings && app.settings.sites) || [];
	return {
		query,
		searchType,
		routingState: routing,
		title,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		canonicalUrl,
		professional: isProfessionalSearch,
		aspectFilters,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		searchQuery: getSettingValue(state, 'Search', 'SearchQuery') ?? SEARCH_API_URL_SEARCH_QUERY,
	};
};
interface SearchDispatchProps {
	setQuery: typeof searchActions.setQuery;
	setSearchType: typeof searchActions.setSearchType;
	updateSearch: typeof routingActions.updateSearch;
}

export default connect(mapStateToProps, {
	setQuery: searchActions.setQuery,
	setSearchType: searchActions.setSearchType,
	updateSearch: routingActions.updateSearch,
})(injectRoutable(injectIntl(Search)));
