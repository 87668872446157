import * as React from 'react';
import styled from 'styled-components';
import { ArrowUpIcon } from 'styleguide/components/Icons';
import { withWindow } from 'styleguide/helpers';
import { FormattedMessage } from 'react-intl';
import ScrollMenu from 'common/components/ScrollMenu/ScrollMenu';

const BackToTopButton = styled.button`
	background-color: ${({ theme }) => theme.colors.brandPrimary};
	font-weight: 600;
	font-family: ${({ theme }) => theme.fonts.primary};
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	line-height: 60px;
	width: 100%;
	border: 0 none;

	> svg {
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const BackToTop: React.FC = () => {
	const onButtonClick = () => {
		withWindow(w => {
			w.scrollTo({ top: 0, behavior: 'smooth' });
		});
	};

	return (
		<ScrollMenu>
			<BackToTopButton onClick={onButtonClick}>
				<FormattedMessage id="search_edit_search" /> <ArrowUpIcon color="#fff" />
			</BackToTopButton>
		</ScrollMenu>
	);
};

export default BackToTop;
