import * as React from 'react';

const RecipeCooperationIcon: React.FC<{ size?: number }> = ({ size = 114 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 114 114" xmlns="http://www.w3.org/2000/svg">
			<circle fill="#8969AA" cx="57" cy="57" r="57" />
			<path
				d="M76.12 30.177c-2.77-.742-5.647.919-6.39 3.69l-8.606 32.116a11.824 11.824 0 00-1.56-1.663c-2.167-1.926-5.286-3.537-8.937-4.515-3.652-.98-7.158-1.143-10-.56-2.846.583-5.236 1.984-5.885 4.406-.993 3.706-1.082 7.975.305 11.755 1.382 3.783 4.367 7.05 9.165 8.337 4.799 1.286 9.019-.052 12.106-2.638 3.09-2.58 5.149-6.321 6.142-10.027l9.79-36.537a2.588 2.588 0 013.195-1.845 2.588 2.588 0 011.845 3.195l-.338 1.26 2.52.675.338-1.26c.742-2.77-.92-5.647-3.69-6.39zM49.95 62.326c3.309.886 6.11 2.375 7.889 3.949 1.773 1.572 2.385 3.07 2.1 4.129-.282 1.058-1.561 2.05-3.883 2.525-2.326.473-5.498.362-8.805-.524-3.308-.886-6.11-2.376-7.888-3.95-1.773-1.572-2.385-3.07-2.101-4.128.283-1.058 1.562-2.05 3.884-2.525 2.326-.474 5.498-.363 8.805.524zm-13.33 7.067c.32.35.65.69 1.017 1.016 2.168 1.926 5.287 3.537 8.939 4.516 3.65.978 7.157 1.142 9.999.558.48-.098.937-.23 1.386-.372-.904 1.525-2.014 2.91-3.312 3.997-2.568 2.14-5.74 3.194-9.761 2.116-4.021-1.077-6.242-3.577-7.396-6.713-.581-1.59-.85-3.345-.87-5.118h-.001z"
				fill="#FFF"
			/>
		</svg>
	);
};

export default RecipeCooperationIcon;
