import styled from 'styled-components';

const FilterCount = styled.span`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 12px;
	font-weight: 400;
	display: inline-block;
	color: ${props => props.theme.colors.bodyText};
`;

export default FilterCount;
