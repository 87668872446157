import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { SearchState, SearchFilterItem } from '../interfaces';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import ProductSearchResults from './ProductSearchResults';
import { SearchFilterName, SearchType } from 'pagetypes/Search/types';
import ProductSearchFilters from './ProductSearchFilters';
import { searchActions } from '../reducers/search-actions';
import { SearchActions } from 'common/components/Search';
import { queryToFilters, getSelectedItems } from '../utils';
import { NameAndValue } from 'common/interfaces/common';
import { routingActions, RoutingState } from 'common/components/Routing/reducers';
import { addToSearch, removeFromSearch, removeKeyFromSearch, replaceToSearch } from 'utils/query-string';
import InfiniteScrollHelper from 'common/components/InfiniteScroll/InfiniteScrollHelper';
import { getSettingValue, getSiteUrlId } from 'common/components/App/services';
import { normalizeSearchUrl, renameQueryStringParameter } from '../searchApiHelper';
import { SEARCH_API_URL_SEARCH_QUERY, SEARCH_PRODUCTS_PAGE_SIZE } from '../constants';
import { clearHashString, getHashSearchPageOrDefault, setHashSearchPage } from '../../../utils/hash-string';
import { resumeScrollPositionFromHash, saveScrollPositionToHash } from '../../../utils/scroll';

type Props = {} & SearchProductsStateProps & SearchProductsDispatchProps;

class SearchProducts extends React.Component<Props> {
	private infiniteScroll: InfiniteScrollHelper;
	private initialPageCount = getHashSearchPageOrDefault();
	private currentPageNumber = getHashSearchPageOrDefault();
	private isComponentMounted = false;
	private hasResetScroll = false;
	private readonly pageSize = SEARCH_PRODUCTS_PAGE_SIZE;

	constructor(props: Props) {
		super(props);
		this.infiniteScroll = new InfiniteScrollHelper(this.initialPageCount);
		this.infiniteScroll.subscribe(this.onInfiniteScroll.bind(this));
	}

	public componentDidMount() {
		const { routingState } = this.props;
		this.doSearchProducts(routingState.search || '');
		this.isComponentMounted = true;
	}

	public componentWillUnmount() {
		this.infiniteScroll.unsubscribeInfiniteScroll();
	}

	public componentDidUpdate(prevProps: Props) {
		this.infiniteScroll.unlockInfiniteScroll(prevProps.search.products, this.props.search.products);

		if (prevProps.routingState.search !== this.props.routingState.search) {
			this.initialPageCount = 1;
			this.currentPageNumber = 1;
			const { routingState } = this.props;
			this.infiniteScroll.reset();
			this.doSearchProducts(routingState.search || '');
		}
	}

	public render() {
		const { search, selectedFilters, searchKeyword, searchUrl } = this.props;
		const { isLoading, products, count, isResultsLoading, language, availableFilters } = search;

		return (
			<>
				<SearchActions
					renderFilters={this.renderFilters}
					resultsCount={count}
					searchType={SearchType.PRODUCT}
					selectedFilters={selectedFilters}
					onFilterClick={this.onFilterClick}
					filters={availableFilters}
				/>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<ProductSearchResults
						results={products}
						language={language}
						isResultsLoading={!!isResultsLoading}
						searchType={SearchType.PRODUCT}
						onMount={this.resumeScrollIfContentMounted}
						onRecipeClick={this.savePageAndScrollPosition}
						searchUrl={searchUrl}
						searchKeyword={searchKeyword}
					/>
				)}
			</>
		);
	}

	private resumeScrollIfContentMounted = () => {
		if (this.isComponentMounted && !this.hasResetScroll) {
			resumeScrollPositionFromHash();
			clearHashString();
			this.infiniteScroll.reset(this.initialPageCount);
			this.hasResetScroll = true;
		}
	};

	private savePageAndScrollPosition = () => {
		saveScrollPositionToHash();
		setHashSearchPage(this.currentPageNumber);
	};

	private doSearchProducts(searchUrl: string) {
		const { siteUrlId, searchProducts } = this.props;

		const finalSearchUrl = this.translateSearchUrlForAPICall(searchUrl);
		searchProducts(siteUrlId, finalSearchUrl || '', this.pageSize * this.initialPageCount);
	}

	private translateSearchUrlForAPICall(search: string | undefined) {
		if (!search) {
			return search;
		}

		let searchUrl = normalizeSearchUrl(search);

		searchUrl = renameQueryStringParameter(searchUrl, this.props.searchQuery, SEARCH_API_URL_SEARCH_QUERY);

		return searchUrl;
	}

	private renderFilters = () => {
		const {
			search: { count, availableFilters, filterCount, isResultsLoading },
			selectedFilters,
		} = this.props;

		return (
			<ProductSearchFilters
				filters={availableFilters}
				filterCount={filterCount}
				resultsCount={count}
				selectedFilters={selectedFilters}
				onFilterClick={this.onFilterClick}
				onRadioFilterClick={this.onRadioFilterClick}
				isResultsLoading={isResultsLoading}
			/>
		);
	};

	private onInfiniteScroll = (pageNumber: number) => {
		this.currentPageNumber = pageNumber;
		const { siteUrlId, routingState, loadMoreProducts, search } = this.props;
		const { products, count } = search;
		if (products.length === count) {
			return;
		}

		const apiSearchUrl = this.translateSearchUrlForAPICall(routingState.search);
		loadMoreProducts(siteUrlId, apiSearchUrl || '', this.pageSize, pageNumber);
	};

	private onFilterClick = (filterName: string, filterValue: string, selected: boolean, items?: SearchFilterItem[]) => {
		const { updateSearch, routingState, selectedFilters } = this.props;
		let search = routingState.search || '';
		if (!selected && items && items.length > 0) {
			const selectedChildren = getSelectedItems(items, selectedFilters);
			search = selectedChildren.reduce((memo, { name, value }) => {
				return removeFromSearch(memo, name, value);
			}, search);
		}
		search = (selected ? addToSearch : removeFromSearch)(search, filterName, filterValue);
		updateSearch(search);
	};

	private onRadioFilterClick = (filterName: string, filterValue: string, selected: boolean) => {
		const { updateSearch, routingState } = this.props;
		let search = routingState.search || '';
		if (filterName === SearchFilterName.bigPackaging || filterName === SearchFilterName.takeaway) {
			search = removeKeyFromSearch(search, SearchFilterName.bigPackaging);
			search = removeKeyFromSearch(search, SearchFilterName.takeaway);
			if (selected) {
				search = replaceToSearch(search, filterName, filterValue);
			}
		} else {
			search = (selected ? replaceToSearch : removeFromSearch)(routingState.search || '', filterName, filterValue);
		}
		updateSearch(search);
	};
}

interface SearchProductsStateProps {
	resourceId: string;
	search: SearchState;
	routingState: RoutingState;
	selectedFilters: NameAndValue[];
	siteUrlId: string;
	searchQuery: string;
	searchUrl: string;
	searchKeyword: string;
}

const mapStateToProps: MapStateToProps<SearchProductsStateProps, {}, State> = (state: State) => {
	const { resource, routing, app } = state;
	const sites = (app.settings && app.settings.sites) || [];
	const searchQuery = getSettingValue(state, 'Search', 'SearchQuery') ?? SEARCH_API_URL_SEARCH_QUERY;
	const searchKeyword = routing.query && searchQuery ? routing.query[searchQuery]?.toString() : '';

	return {
		resourceId: resource.id || '',
		search: resource.content as SearchState,
		routingState: routing,
		selectedFilters: queryToFilters(routing.query || {}),
		siteUrlId: getSiteUrlId(routing, sites) || '',
		searchQuery,
		searchUrl: routing.pathname,
		searchKeyword,
	};
};

interface SearchProductsDispatchProps {
	loadMoreProducts: typeof searchActions.loadMoreProducts;
	searchProducts: typeof searchActions.searchProducts;
	updateSearch: typeof routingActions.updateSearch;
}

export default connect(mapStateToProps, {
	searchProducts: searchActions.searchProducts,
	loadMoreProducts: searchActions.loadMoreProducts,
	updateSearch: routingActions.updateSearch,
})(SearchProducts);
