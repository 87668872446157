import * as React from 'react';
import { IconWrapperProps } from 'styleguide/interfaces/iconInterfaces';
import styled from 'styled-components';
import ArrowDownIcon from 'styleguide/components/Icons/NormalIcons/ArrowDownIcon/ArrowDownIcon';
import ArrowUpIcon from 'styleguide/components/Icons/NormalIcons/ArrowUpIcon/ArrowUpIcon';
import FilterCount from './FilterCount';
import { SearchFilterItem } from '../../interfaces';
import { NameAndValue, NumberMap } from 'common/interfaces/common';
import Truncate from 'common/components/General/Truncate';
import ProductGroupFilterItem from './ProductGroupFilterItem';
import { isFilterSelected, getSelectedItems } from '../../utils';

const SELECTED_VALUE_TRUNCATED_LENGTH = 30;

interface StyledProps {
	selected?: boolean;
	disabled?: boolean;
}

const StyledProductGroupFilter = styled.article<StyledProps>`
	border: 1px solid
		${props =>
			props.disabled
				? props.theme.colors.border
				: props.selected
				? props.theme.colors.selectedItem
				: props.theme.colors.borderHighlight};
	background: ${props =>
		props.disabled
			? props.theme.colors.inputDisabledBackground
			: props.selected
			? props.theme.colors.selectedItemBackground
			: props.theme.colors.white};
	color: ${props =>
		props.disabled
			? props.theme.colors.inputDisabledText
			: props.selected
			? props.theme.colors.brandPrimary
			: props.theme.colors.buttonPrimary};
	border-radius: 4px;
`;

const ProductGroupFilterHeader = styled.header`
	${props => props.theme.typography.heading};
	font-size: 15px;
	padding: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const FilterIconWrapper = styled.span`
	width: 50px;
	height: 50px;
	margin-right: 5px;
	flex: 0 0 50px;

	img {
		width: 100%;
		height: 100%;
		display: block;
	}
`;

const FilterTitleWrapper = styled.span`
	display: block;

	.filter-title-count {
		display: inline;
		margin-left: 5px;
	}
`;

const SelectedChildFilterValue = styled.div`
	font-family: ${props => props.theme.fonts.secondary};
	font-weight: 400;
	font-size: 13px;
	color: ${props => props.theme.colors.bodyText};
	line-height: 12px;
`;

const ToggleIconWrapper = styled.span<StyledProps>`
	justify-self: flex-end;
	margin-left: auto;
	margin-right: 10px;
	width: 26px;
	height: 26px;
	text-align: center;
	cursor: pointer;

	svg {
		width: 20px;
		path {
			fill: ${props =>
				props.disabled
					? props.theme.colors.inputDisabledText
					: props.selected
					? props.theme.colors.brandPrimary
					: props.theme.colors.buttonPrimary};
		}
	}
`;

const FilterItemList = styled.ul`
	margin: 6px 0 18px;
	padding: 0 20px 0 24px;
	list-style: none;

	li + li {
		margin-top: 6px;
	}
`;

const FilterItem = styled.li<StyledProps>`
	& > .form-field label {
		font-size: 15px;
		color: ${props => (props.disabled ? props.theme.colors.inputDisabledText : props.theme.colors.bodyText)};
		font-weight: ${props => (props.disabled ? 400 : props.selected ? 600 : 400)};
	}

	ul {
		padding: 0 0 0 30px;
		margin-bottom: 6px;
	}

	.filter-count {
		float: right;
		font-size: 15px;
		line-height: 24px;
		margin-left: 6px;
	}
`;

interface ProductGroupFilterProps extends StyledProps {
	icon?: React.ReactElement<IconWrapperProps & React.SVGAttributes<SVGElement>>;
	title: string;
	count: number;
	items: SearchFilterItem[];
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	filterName: string;
	filterValue: string;
	isResultsLoading?: boolean;
	onFilterClick: (filterName: string, filterValue: string, selected: boolean, items: SearchFilterItem[]) => void;
	onFilterOpen: () => void;
}

type Props = ProductGroupFilterProps & React.HTMLAttributes<HTMLDivElement>;

interface ProductGroupFilterState {
	isOpen: boolean;
}

class ProductGroupFilter extends React.Component<Props, ProductGroupFilterState> {
	public filterRef: React.RefObject<any>;

	constructor(props: Props) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.filterRef = React.createRef();
	}

	public componentDidUpdate(prevProps: ProductGroupFilterProps, prevState: ProductGroupFilterState) {
		if (prevState.isOpen !== this.state.isOpen) {
			this.props.onFilterOpen();
			if (this.state.isOpen) {
				this.filterRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}

	public render() {
		const { selected, disabled, icon, title, count, items, onFilterClick, onFilterOpen, ...rest } = this.props;
		const { isOpen } = this.state;
		return (
			<StyledProductGroupFilter selected={selected} disabled={disabled} {...rest} ref={this.filterRef}>
				<ProductGroupFilterHeader onClick={this.onFilterClick}>
					<FilterIconWrapper>{icon}</FilterIconWrapper>
					<span>
						<FilterTitleWrapper>
							{title}
							<FilterCount className="filter-title-count">{count}</FilterCount>
						</FilterTitleWrapper>
						{!isOpen && this.renderSelectedChildFilters()}
					</span>
					{items.length > 0 && (
						<ToggleIconWrapper selected={selected} disabled={disabled} onClick={this.onToggleChildFilters}>
							{!isOpen && <ArrowDownIcon className="filter-toggle" />}
							{isOpen && <ArrowUpIcon className="filter-toggle" />}
						</ToggleIconWrapper>
					)}
				</ProductGroupFilterHeader>
				{isOpen && items.length > 0 && this.renderFilterItems(items)}
			</StyledProductGroupFilter>
		);
	}

	private renderSelectedChildFilters() {
		const { items, selectedFilters } = this.props;
		const selectedChildren = getSelectedItems(items, selectedFilters);

		if (selectedChildren.length === 0) {
			return null;
		}

		const selectedChildTitles = selectedChildren.map(c => c.title);

		return (
			<SelectedChildFilterValue>
				<Truncate value={selectedChildTitles.join(', ')} truncateAt={SELECTED_VALUE_TRUNCATED_LENGTH} />
			</SelectedChildFilterValue>
		);
	}

	private renderFilterItems(filterItems: SearchFilterItem[]): JSX.Element {
		return <FilterItemList>{filterItems.map(item => this.renderFilterItem(item))}</FilterItemList>;
	}

	private renderFilterItem(item: SearchFilterItem): JSX.Element {
		const { selectedFilters, filterCount, onFilterClick, isResultsLoading } = this.props;
		const { name, value, items } = item;
		const selected = isFilterSelected(item, selectedFilters);
		const count = filterCount[value];
		const disabled = count === undefined || count === 0 || isResultsLoading;

		let childItems;

		if (selected && items.length > 0) {
			childItems = this.renderFilterItems(items);
		}

		return (
			<FilterItem key={`${name}-${value}`} selected={selected} disabled={disabled}>
				<ProductGroupFilterItem
					{...item}
					selected={selected}
					disabled={disabled}
					count={count}
					onFilterClick={onFilterClick}
				/>
				{childItems}
			</FilterItem>
		);
	}

	private onFilterClick = () => {
		const { filterName, filterValue, selected, disabled, onFilterClick, items } = this.props;

		if (!disabled) {
			onFilterClick(filterName, filterValue, !selected, items);
			this.setState({ isOpen: !selected });
		}
	};

	private onToggleChildFilters = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.stopPropagation();
		const { isOpen } = this.state;
		const { selected, disabled, items, filterName, filterValue, onFilterClick } = this.props;
		if (!disabled && !selected && !isOpen) {
			onFilterClick(filterName, filterValue, !selected, items);
		} else if (selected && isOpen) {
			onFilterClick(filterName, filterValue, false, items);
		}
		this.setState({ isOpen: !this.state.isOpen });
	};
}

export default ProductGroupFilter;
