import * as React from 'react';
import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import RecipeCard from 'styleguide/components/Cards/RecipeCard/RecipeCard';
import { SearchType } from 'pagetypes/Search/types';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import SearchResultsGrid from './SearchResultsGrid';
import NoResults from './NoResults';

export interface RecipeSearchResultsProps {
	results: RecipeCardInterface[];
	searchType?: SearchType;
	isResultsLoading: boolean;
	hideRecipeInfo?: boolean;
	language?: string | null;
	onRecipeClick?: () => void;
	onMount?: () => void;
	searchUrl?: string;
	searchKeyword?: string;
}

class RecipeSearchResults extends React.Component<RecipeSearchResultsProps & InjectedRoutableProps> {
	public componentDidMount() {
		if (this.props.onMount) {
			this.props.onMount();
		}
	}

	public render() {
		const { results, isResultsLoading, searchKeyword, searchUrl } = this.props;
		if (!isResultsLoading && results.length === 0) {
			return (
				<NoResults
					searchUrl={searchUrl}
					searchKeyword={searchKeyword}
					noResultsButtonLabel="component_no_results_recipe_button"
					noResultsDescription="search_no_results_recipes_with_keyword"
				/>
			);
		}

		return (
			<SearchResultsGrid>
				{this.renderResults(results)}
				{isResultsLoading && <LoadingSpinner />}
			</SearchResultsGrid>
		);
	}

	private renderResults(recipeResults: RecipeCardInterface[]) {
		const { onLinkClick, hideRecipeInfo, language } = this.props;
		const onRecipeCardClick: InjectedRoutableProps['onLinkClick'] = event => {
			if (this.props.onRecipeClick) {
				this.props.onRecipeClick();
			}
			onLinkClick(event);
		};

		return recipeResults.map(recipe => (
			<RecipeCard
				key={recipe.url}
				{...recipe}
				language={language}
				onClick={onRecipeCardClick}
				className="search-result-card"
				hideRecipeInfo={hideRecipeInfo}
			/>
		));
	}
}

export default injectRoutable(RecipeSearchResults);
