import * as React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import { SearchState } from '../interfaces';
import { State } from 'common/reducers';
import RecipeSearchResults from './RecipeSearchResults';
import { SearchFilterName, SearchType } from 'pagetypes/Search/types';
import RecipeSearchFilters from './RecipeSearchFilters';
import { searchActions } from '../reducers/search-actions';
import { queryToFilters } from '../utils';
import { NameAndValue } from 'common/interfaces/common';
import { routingActions, RoutingState } from 'common/components/Routing/reducers';
import { addToSearch, removeFromSearch, replaceToSearch, parseQuery } from 'utils/query-string';
import InfiniteScrollHelper from 'common/components/InfiniteScroll/InfiniteScrollHelper';
import { SearchActions } from 'common/components/Search';
import { SortOrder } from 'common/components/SortOrder/SortOrderSelect';
import { ResourceContextName } from 'common/components/Resource/interfaces';
import {
	SEARCH_SORT_VALUE_NEWEST,
	SEARCH_SORT_VALUE_FAVOURITE,
	SEARCH_SORT_VALUE_BEST_MATCH,
	SEARCH_SORT_VALUE_ALPHABETICAL,
	SEARCH_RECIPES_PAGE_SIZE,
	SEARCH_API_URL_SORT_PARAMETER,
	SEARCH_API_URL_SEARCH_QUERY,
} from '../constants';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getSiteUrlId, getSettingValue, getSite, SiteId } from 'common/components/App/services';
import { normalizeSearchUrl, renameQueryStringParameter, translateQueryStringParameter } from '../searchApiHelper';
import { clearHashString, getHashSearchPageOrDefault, setHashSearchPage } from '../../../utils/hash-string';
import { resumeScrollPositionFromHash, saveScrollPositionToHash } from '../../../utils/scroll';

type Props = {} & SearchRecipesStateProps & SearchRecipesDispatchProps & WrappedComponentProps;

class SearchRecipes extends React.Component<Props> {
	private infiniteScroll: InfiniteScrollHelper;
	private initialPageCount = getHashSearchPageOrDefault();
	private currentPageNumber = getHashSearchPageOrDefault();
	private isComponentMounted = false;
	private hasResetScroll = false;
	private readonly pageSize = SEARCH_RECIPES_PAGE_SIZE;

	constructor(props: Props) {
		super(props);
		this.infiniteScroll = new InfiniteScrollHelper(this.initialPageCount);
		this.infiniteScroll.subscribe(this.onInfiniteScroll.bind(this));
	}

	public componentDidMount() {
		const { routingState } = this.props;
		this.doSearchRecipes(routingState.search);
		this.isComponentMounted = true;
	}

	public componentWillUnmount() {
		this.infiniteScroll.unsubscribeInfiniteScroll();
	}

	public componentDidUpdate(prevProps: Props) {
		this.infiniteScroll.unlockInfiniteScroll(prevProps.search.recipes, this.props.search.recipes);

		if (prevProps.routingState.search !== this.props.routingState.search) {
			this.initialPageCount = 1;
			this.currentPageNumber = 1;
			const { routingState } = this.props;
			this.infiniteScroll.reset();
			this.doSearchRecipes(routingState.search);
		}
	}

	public render() {
		const {
			search: { isLoading, recipes, count, isResultsLoading, isProfessionalSearch, language, availableFilters },
			hideRecipeInfo,
			searchUrl,
			searchKeyword,
		} = this.props;

		return (
			<>
				<SearchActions
					renderFilters={this.renderFilters}
					resultsCount={count}
					searchType={SearchType.RECIPE}
					defaultSortValue={this.getSortValue(true)}
					sortOptions={this.getSortOptions()}
					onSortClick={this.onSortClick}
					onFilterClick={this.onFilterClick}
					selectedFilters={this.props.selectedFilters}
					isProfessional={isProfessionalSearch}
					filters={availableFilters}
				/>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<RecipeSearchResults
						results={recipes}
						language={language}
						searchType={SearchType.RECIPE}
						isResultsLoading={!!isResultsLoading}
						hideRecipeInfo={isProfessionalSearch || hideRecipeInfo}
						onMount={this.resumeScrollIfContentMounted}
						onRecipeClick={this.savePageAndScrollPosition}
						searchUrl={searchUrl}
						searchKeyword={searchKeyword}
					/>
				)}
			</>
		);
	}

	private resumeScrollIfContentMounted = () => {
		if (this.isComponentMounted && !this.hasResetScroll) {
			resumeScrollPositionFromHash();
			clearHashString();
			this.infiniteScroll.reset(this.initialPageCount);
			this.hasResetScroll = true;
		}
	};

	private savePageAndScrollPosition = () => {
		saveScrollPositionToHash();
		setHashSearchPage(this.currentPageNumber);
	};

	private renderFilters = () => {
		const { search, selectedFilters } = this.props;
		const { availableFilters, filterCount, isResultsLoading } = search;

		return (
			<RecipeSearchFilters
				filters={availableFilters}
				filterCount={filterCount}
				selectedFilters={selectedFilters}
				isResultsLoading={isResultsLoading}
				professional={search.isProfessionalSearch}
				onFilterClick={this.onFilterClick}
				onRadioFilterClick={this.onRadioFilterClick}
			/>
		);
	};

	private doSearchRecipes(search: string | undefined) {
		const {
			siteUrlId,
			searchRecipes,
			loadProduct,
			selectedProductSlug,
			selectedProductTitle,
			ingredientsParameterName,
		} = this.props;
		const { isProfessionalSearch } = this.props.search;

		const parsedQuery = parseQuery(search || '');
		// tslint:disable-next-line:no-string-literal
		const productSlugs = parsedQuery[ingredientsParameterName];
		const productSlug = Array.isArray(productSlugs) ? productSlugs[0] : productSlugs;
		if (productSlug && (productSlug !== selectedProductSlug || !selectedProductTitle)) {
			loadProduct(productSlug);
		}

		const searchUrl = this.translateSearchUrlForAPICall(search);

		searchRecipes(siteUrlId, isProfessionalSearch, searchUrl || '', this.pageSize * this.initialPageCount);
	}

	private translateSearchUrlForAPICall(search: string | undefined) {
		if (!search) {
			return search;
		}

		let searchUrl = normalizeSearchUrl(search);

		searchUrl = translateQueryStringParameter(
			searchUrl,
			this.props.sortParameter,
			SEARCH_API_URL_SORT_PARAMETER,
			this.props.sortTranslations
		);

		searchUrl = renameQueryStringParameter(searchUrl, this.props.searchQuery, SEARCH_API_URL_SEARCH_QUERY);

		return searchUrl;
	}

	private onInfiniteScroll = (pageNumber: number) => {
		this.currentPageNumber = pageNumber;

		const { siteUrlId, routingState, loadMoreRecipes, search } = this.props;
		const { recipes, count, isProfessionalSearch } = search;
		if (recipes.length === count) {
			return;
		}

		const apiSearchUrl = this.translateSearchUrlForAPICall(routingState.search);
		loadMoreRecipes(siteUrlId, isProfessionalSearch, apiSearchUrl || '', this.pageSize, pageNumber);
	};

	private getSortOptions(): SortOrder[] {
		const {
			intl,
			search: { isProfessionalSearch },
		} = this.props;

		const sortValue = this.getSortValue();
		const initialSelectedSort = Array.isArray(sortValue) ? sortValue[0] : sortValue;

		const sortOptions = [
			{
				value: this.props.sortByBestHit,
				label: intl.formatMessage({ id: 'search_recipe_sort_best_hit' }),
				selected: initialSelectedSort ? initialSelectedSort === this.props.sortByBestHit : true,
			},
			{
				value: this.props.sortByNewestFirst,
				label: intl.formatMessage({ id: 'search_recipe_sort_newest_first' }),
				selected: initialSelectedSort === this.props.sortByNewestFirst,
			},
			{
				value: this.props.sortByPopularity,
				label: intl.formatMessage({ id: 'search_recipe_sort_most_popular' }),
				selected: initialSelectedSort === this.props.sortByPopularity,
			},
		];

		if (!isProfessionalSearch) {
			sortOptions.push({
				value: this.props.sortByAlphabetical,
				label: intl.formatMessage({ id: 'search_recipe_sort_alphabetical' }),
				selected: initialSelectedSort === this.props.sortByAlphabetical,
			});
		}

		return sortOptions;
	}

	private getSortValue(getDefaultOnly?: boolean) {
		const { routingState } = this.props;

		const sortValue = routingState.query && routingState.query[this.props.sortParameter];
		if (sortValue && !getDefaultOnly) {
			return Array.isArray(sortValue) ? sortValue[0] : sortValue;
		}

		return this.props.sortByBestHit;
	}

	private onSortClick = (sort: string) => {
		const { updateSearch, routingState } = this.props;
		const search = replaceToSearch(routingState.search || '', this.props.sortParameter, sort);
		updateSearch(search);
	};

	private onFilterClick = (filterName: string, filterValue: string, selected: boolean) => {
		const { updateSearch, routingState } = this.props;
		const search = (selected ? addToSearch : removeFromSearch)(routingState.search || '', filterName, filterValue);
		updateSearch(search);
	};

	private onRadioFilterClick = (filterName: string, filterValue: string, selected: boolean) => {
		const { updateSearch, routingState } = this.props;
		const search = (selected ? replaceToSearch : removeFromSearch)(routingState.search || '', filterName, filterValue);
		updateSearch(search);
	};
}

interface SearchRecipesStateProps {
	context: ResourceContextName;
	search: SearchState;
	routingState: RoutingState;
	selectedFilters: NameAndValue[];
	selectedProductTitle?: string;
	selectedProductSlug?: string;
	siteUrlId: string;
	hideRecipeInfo: boolean;

	searchQuery: string;
	sortParameter: string;
	sortByBestHit: string;
	sortByNewestFirst: string;
	sortByPopularity: string;
	sortByAlphabetical: string;
	sortTranslations: { [id: string]: string };

	ingredientsParameterName: string;

	searchUrl: string;
	searchKeyword: string;
}

const mapStateToProps: MapStateToProps<SearchRecipesStateProps, {}, State> = (state: State) => {
	const { resource, routing, app } = state;
	const search = resource.content as SearchState;
	const filter = search && search.recipeProductFilter;
	const sites = (app.settings && app.settings.sites) || [];

	const sortByBestHit = getSettingValue(state, 'RecipeSearch', 'SortByBestHit');
	const sortByNewestFirst = getSettingValue(state, 'RecipeSearch', 'SortByNewestFirst');
	const sortByPopularity = getSettingValue(state, 'RecipeSearch', 'SortByPopularity');
	const sortByAlphabetical = getSettingValue(state, 'RecipeSearch', 'SortByAlphabetical');
	const ingredientsParameterName = getSettingValue(state, 'RecipeSearch', 'IngredientsParameter');

	const sortTranslation: { [id: string]: string } = {};
	if (!!sortByBestHit) {
		sortTranslation[sortByBestHit] = SEARCH_SORT_VALUE_BEST_MATCH;
	}
	if (!!sortByNewestFirst) {
		sortTranslation[sortByNewestFirst] = SEARCH_SORT_VALUE_NEWEST;
	}
	if (!!sortByPopularity) {
		sortTranslation[sortByPopularity] = SEARCH_SORT_VALUE_FAVOURITE;
	}
	if (!!sortByAlphabetical) {
		sortTranslation[sortByAlphabetical] = SEARCH_SORT_VALUE_ALPHABETICAL;
	}

	const siteId = getSite(state.routing, sites)?.id;
	const searchQuery = getSettingValue(state, 'Search', 'SearchQuery') ?? SEARCH_API_URL_SEARCH_QUERY;
	const searchKeyword = routing.query && searchQuery ? routing.query[searchQuery]?.toString() : '';

	return {
		context: resource.context,
		search: resource.content as SearchState,
		routingState: routing,
		selectedFilters: queryToFilters(routing.query || {}),
		selectedProductTitle: filter && filter.productTitle,
		selectedProductSlug: filter && filter.productSlug,
		siteUrlId: getSiteUrlId(routing, sites) || '',

		searchQuery,
		sortParameter: getSettingValue(state, 'RecipeSearch', 'SortParameter') ?? SEARCH_API_URL_SORT_PARAMETER,

		sortByBestHit: sortByBestHit ?? SEARCH_SORT_VALUE_BEST_MATCH,
		sortByNewestFirst: sortByNewestFirst ?? SEARCH_SORT_VALUE_NEWEST,
		sortByPopularity: sortByPopularity ?? SEARCH_SORT_VALUE_FAVOURITE,
		sortByAlphabetical: sortByAlphabetical ?? SEARCH_SORT_VALUE_ALPHABETICAL,

		ingredientsParameterName: ingredientsParameterName ?? SearchFilterName.ingredients,

		sortTranslations: sortTranslation,
		hideRecipeInfo: siteId === SiteId.finc,

		searchUrl: routing.pathname,
		searchKeyword,
	};
};

interface SearchRecipesDispatchProps {
	loadMoreRecipes: typeof searchActions.loadMoreRecipes;
	searchRecipes: typeof searchActions.searchRecipes;
	loadProduct: typeof searchActions.recipeProductFilterLoadProduct;
	updateSearch: typeof routingActions.updateSearch;
}

export default connect(mapStateToProps, {
	searchRecipes: searchActions.searchRecipes,
	loadMoreRecipes: searchActions.loadMoreRecipes,
	loadProduct: searchActions.recipeProductFilterLoadProduct,
	updateSearch: routingActions.updateSearch,
})(injectIntl(SearchRecipes));
