import * as React from 'react';
import Modal from 'styleguide/components/Modals/Modal/Modal';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import Button from 'styleguide/components/Buttons/Button/Button';
import styled, { css } from 'styled-components';
import ArrowDownIcon from 'styleguide/components/Icons/NormalIcons/ArrowDownIcon/ArrowDownIcon';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { withWindow } from 'styleguide/helpers/window';
import { SearchFilterType } from 'pagetypes/Search/types';
import { ModalProps } from 'styleguide/components/Modals/Modal/Modal';
import { FormattedMessage } from 'react-intl';
import { ToggleMedia, toggleMedia } from 'common/components/Media';

const FilterLabel = styled.button`
	${props => props.theme.typography.heading};
	font-size: 18px;
	line-height: 60px;
	color: ${props => props.theme.colors.brandPrimary};
	text-transform: uppercase;
	background: transparent;
	border: none;
	border-top: 1px solid ${props => props.theme.colors.border};
	padding: 0 16px;
	width: 100%;
	text-align: left;
	cursor: pointer;

	&:last-child {
		border-bottom: 1px solid ${props => props.theme.colors.border};

		${media.desktop`
			border: none;
		`};
	}

	${media.desktop`
		font-family: ${props => props.theme.fonts.secondary};		
		font-size: 15px;
		font-weight: 600;
		line-height: 18px;
		text-transform: none;
		border: none;
		padding: 0;
		width: auto;
		white-space: nowrap;
	`};

	&:active,
	&:focus,
	&:hover {
		outline: none;
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.shoppingListGrey};
		cursor: default;

		path {
			fill: ${({ theme }) => theme.colors.shoppingListGrey};
		}
	}

	& + & {
		${media.desktop`
			margin-left: 14px;
		`};

		${media.desktop1440`
			margin-left: 30px;
		`};
	}
`;

interface FilterTypeProps {
	filterType?: SearchFilterType;
}

const ModalActionsWrapper = styled.div<FilterTypeProps>`
	text-align: center;

	& > button {
		margin: 0 auto;
	}

	& > .divider {
		margin: 30px 0;
	}

	${props =>
		props.filterType === 'ProductGroup'
			? css`
					padding: 30px 0;

					& > .divider {
						display: none;
					}
			  `
			: ''};
`;

const FilterModal = styled(Modal)<ModalProps & React.HTMLAttributes<HTMLDivElement>>`
	overflow: auto;

	${toggleMedia('block', 'show', 'desktop')};

	.filter-modal-title {
		margin: 0 0 30px;
	}

	${props =>
		props.className === 'filter-modal-productgroup'
			? css`
					.modal-content {
						padding: 0;
					}
					.filter-modal-title {
						margin: 0 50px 30px;
					}
			  `
			: ''};
`;

interface ToggleIconProps {
	isOpen: boolean;
}
const ToggleIconWrapper = styled.div<ToggleIconProps>`
	float: right;
	margin-top: 17px;
	transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};

	${media.desktop`
		vertical-align: -2px;
		margin-left: 8px;
		margin-top: 0;
		float: none;
		transform: none;
		display: inline-block;
	`};

	& > svg {
		width: 26px;
		height: 26px;

		${media.desktop`
			width: 14px;
			height: 14px;
		`};
	}
`;
const ToggleIcon: React.FC<ToggleIconProps> = ({ isOpen }) => {
	return (
		<ToggleIconWrapper className="toggle-icon" isOpen={isOpen}>
			<ArrowDownIcon />
		</ToggleIconWrapper>
	);
};

interface SearchFilterState {
	isOpen: boolean;
}

interface SearchFilterProps extends FilterTypeProps {
	label: string;
	isDisabled?: boolean;
	modalTitle?: string;
	modalDesktopWidth?: string;
}

class SearchFilter extends React.Component<SearchFilterProps, SearchFilterState> {
	public labelRef: React.RefObject<any>;

	constructor(props: SearchFilterProps) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.labelRef = React.createRef();
	}

	public componentDidUpdate(prevProps: SearchFilterProps, prevState: SearchFilterState) {
		if (prevState.isOpen !== this.state.isOpen && this.state.isOpen) {
			withWindow(w => {
				if (w.innerWidth < mediaBreakpoints.desktop) {
					const scrollTargetEl = document.getElementById('modal-filters-content-wrapper');
					if (scrollTargetEl) {
						const top = this.labelRef.current.offsetTop;
						scrollTargetEl.scrollTo({ top, behavior: 'smooth' });
					}
				}
			});
		}
	}

	public render() {
		const { label, children, isDisabled } = this.props;
		const { isOpen } = this.state;
		return (
			<>
				<FilterLabel onClick={this.toggleOpen} disabled={isDisabled} ref={this.labelRef}>
					{label}
					<ToggleIcon isOpen={isOpen} />
				</FilterLabel>
				{isOpen && (
					<>
						{this.renderModalFilters()}
						<ToggleMedia displayType="block" toggleType="hide" breakpoint="desktop">
							{children}
						</ToggleMedia>
					</>
				)}
			</>
		);
	}

	private renderModalFilters() {
		const { label, modalTitle, modalDesktopWidth, filterType, children } = this.props;

		return (
			<FilterModal
				className={`filter-modal-${filterType ? filterType.toLowerCase() : ''}`}
				desktopWidth={modalDesktopWidth}
				onClose={this.toggleOpen}>
				<MediumHeader tagName="p" className="filter-modal-title">
					{modalTitle || label}
				</MediumHeader>
				{children}
				<ModalActionsWrapper filterType={filterType}>
					<Divider className="divider" size={DividerSize.full} direction={DividerDirection.horizontal} />
					<Button onClick={this.toggleOpen}>
						<FormattedMessage id="global_ready" />
					</Button>
				</ModalActionsWrapper>
			</FilterModal>
		);
	}

	private toggleOpen = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};
}

export default SearchFilter;
