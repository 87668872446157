import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';

export interface ContentTagProps {
	className?: string;
	color?: string;
	size?: 'small' | 'large';
	disableMobileSize?: boolean;
}

export const getContentTagHeight = (size?: 'small' | 'large', isMobile?: boolean) => {
	let height = isMobile ? '24px' : '30px';
	if (size === 'large') {
		height = isMobile ? '40px' : '60px';
	}
	return height;
};

const getPadding = (size?: 'small' | 'large', isMobile?: boolean) => {
	let padding = '0 12px';
	if (size === 'large') {
		padding = isMobile ? '0 30px' : '0 38px';
	}
	return padding;
};

const StyledContentTag = styled.span<ContentTagProps>`
	background: ${props => props.color || props.theme.colors.brandPrimary};
	color: ${props => props.theme.colors.white};
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 11px;
	font-weight: 600;
	line-height: ${props => getContentTagHeight(props.size, !Boolean(props.disableMobileSize))};
	letter-spacing: 1.4px;
	text-transform: uppercase;
	padding: ${props => getPadding(props.size, !Boolean(props.disableMobileSize))};
	height: ${props => getContentTagHeight(props.size, !Boolean(props.disableMobileSize))};
	border-radius: 2px;
	display: inline-block;
	white-space: nowrap;
	max-width: 185px;
	text-overflow: ellipsis;
	overflow: hidden;

	${media.desktop<ContentTagProps>`
		padding: ${props => getPadding(props.size)};
		font-size: ${props => (props.size === 'large' ? '14px' : '12px')};
		line-height: ${props => getContentTagHeight(props.size)};
		height: ${props => getContentTagHeight(props.size)};
		letter-spacing: ${props => (props.size === 'large' ? '3px' : '1.5px')};
		max-width: calc(100% + 75px);
	`};
`;

const ContentTag: React.FC<ContentTagProps & React.HTMLAttributes<HTMLSpanElement>> = ({
	color,
	size,
	disableMobileSize,
	className,
	children,
}) => {
	return (
		<StyledContentTag color={color} size={size} disableMobileSize={disableMobileSize} className={className}>
			{children}
		</StyledContentTag>
	);
};

export default ContentTag;
