import * as React from 'react';
import { getFormattedFullDate } from 'utils/date';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import { useIntl } from 'react-intl';
import { AuthorTagStyleProps, AuthorTagWrapper, AuthoredDate } from './AuthorTagWrapper';

const AuthorImage = styled.img<AuthorTagStyleProps>`
	border-radius: 50%;
	width: 32px;
	height: 32px;
	flex: 0 0 32px;

	${media.desktop<AuthorTagStyleProps>`
		${props =>
			props.size === 'small'
				? ''
				: css`
						width: 64px;
						height: 64px;
						flex: 0 0 64px;
				  `}
  `};
`;

const AuthorDetails = styled.span<AuthorTagStyleProps>`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.25;
	margin-left: 8px;

	${media.phone<AuthorTagStyleProps>`
		line-height: ${props => (props.size === 'small' ? '1.25' : '20px')};
	`};

	${media.desktop<AuthorTagStyleProps>`
		${props =>
			props.size === 'small'
				? ''
				: css`
						font-size: 15px;
						margin-left: 15px;
				  `}
  `};
`;

export interface AuthorTagProps extends AuthorTagStyleProps {
	className?: string;
	imageUrl: string;
	name: string;
	date: string;
}

const AuthorTag: React.FC<AuthorTagProps> = ({ className, imageUrl, name, date, size }) => {
	const intl = useIntl();
	const formattedDate = getFormattedFullDate(intl, new Date(date));
	return (
		<AuthorTagWrapper className={className} size={size}>
			<AuthorImage src={imageUrl} size={size} />
			<AuthorDetails size={size}>
				{name}
				<AuthoredDate dateTime={date} size={size}>
					{formattedDate}
				</AuthoredDate>
			</AuthorDetails>
		</AuthorTagWrapper>
	);
};

export default AuthorTag;
