import * as React from 'react';
import ProductCard from 'styleguide/components/Cards/ProductCard/ProductCard';
import { ImageSource } from 'styleguide/interfaces/imgInterfaces';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { SearchType } from 'pagetypes/Search/types';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import SearchResultsGrid from './SearchResultsGrid';
import { SearchProductCard } from '../interfaces';
import NoResults from './NoResults';

export interface ProductSearchResultsProps {
	results: SearchProductCard[];
	searchType?: SearchType;
	isResultsLoading: boolean;
	language?: string | null;
	onRecipeClick?: () => void;
	onMount?: () => void;
	searchUrl?: string;
	searchKeyword?: string;
}

const ProductSearchResultsGrid = styled(SearchResultsGrid)`
	.search-result-card {
		${media.tablet`
			.product-card__name {
				padding: 10px 20px;
			}
		`};

		${media.desktop`
			padding-top: 20px;
			
			.product-card__image-wrapper {
				width: 140px;
 			  height: 140px;
			}
		`};
	}
`;

const ProductSearchSpinner = styled.div`
	text-align: center;
	width: 100%;
	display: block;
`;

class ProductSearchResults extends React.Component<ProductSearchResultsProps & InjectedRoutableProps> {
	public componentDidMount() {
		if (this.props.onMount) {
			this.props.onMount();
		}
	}

	public render() {
		const { results, isResultsLoading, searchKeyword, searchUrl } = this.props;
		if (!isResultsLoading && results.length === 0) {
			return (
				<NoResults
					searchUrl={searchUrl}
					searchKeyword={searchKeyword}
					noResultsButtonLabel="component_no_results_product_button"
					noResultsDescription="search_no_results_products_with_keyword"
				/>
			);
		}

		return (
			<ProductSearchResultsGrid>
				{this.renderResults()}
				{isResultsLoading && (
					<ProductSearchSpinner>
						<LoadingSpinner />{' '}
					</ProductSearchSpinner>
				)}
			</ProductSearchResultsGrid>
		);
	}

	private renderResults() {
		const { onLinkClick, results, language } = this.props;
		const onProductClick: InjectedRoutableProps['onLinkClick'] = event => {
			if (this.props.onRecipeClick) {
				this.props.onRecipeClick();
			}
			onLinkClick(event);
		};

		return results.map(product => {
			const imgSrc: ImageSource[] = [
				{
					url: product.imageUrl as string,
					media: 'tablet',
					width: 100,
				},
				{
					url: product.largeImageUrl as string,
					media: 'desktop',
					width: 140,
					displayWidth: 1000,
				},
			];
			return (
				<ProductCard
					key={product.url}
					{...product}
					language={language}
					imageUrl={imgSrc}
					onClick={onProductClick}
					className="search-result-card"
				/>
			);
		});
	}
}

export default injectRoutable(ProductSearchResults);
