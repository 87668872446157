import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { NameAndValue, NumberMap } from 'common/interfaces/common';
import { SearchFilterItem } from '../../interfaces';
import { Icon } from 'utils/import';
import { getSettingValue } from 'common/components/App/services';
import { State } from 'common/reducers';
import { useIntl } from 'react-intl';

const BrandGridWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: stretch;
	background-color: ${props => props.theme.colors.contentSectionHiglighted};
	margin: 0 1px 1px;
`;

const BrandButtonWrapper = styled.div`
	flex: 0 0 50%;
	width: 50%;
	height: 100px;
	display: block;
	position: relative;
	text-align: center;
	border-color: ${props => props.theme.colors.white};
	border-style: solid;
	border-width: 0px 1px 1px 0px;

	${media.phone560`
    flex: 0 0 33.3333%;
    width: 33.3333%;
  `};

	${media.desktop`
    flex: 0 0 143px;
    width: 143px;
  `};
`;

const BrandButton = styled.button<{ selected: boolean }>`
	cursor: pointer;
	width: 100%;
	height: 100%;
	border-width: 1px;
	border-style: solid;
	padding: 1px;
	background: ${props => (props.selected ? props.theme.colors.selectedItemBackground : 'transparent')};
	border-color: ${props => (props.selected ? props.theme.colors.selectedItem : 'transparent')};

	&:hover {
		cursor: pointer;
		color: ${props => props.theme.colors.brandPrimary};
		background: ${props => props.theme.colors.selectedItemBackground};
		border-color: ${props => props.theme.colors.selectedItem};
	}

	&:active,
	&:focus {
		outline: none;
	}

	> img:not(.brand-image) {
		max-width: 65px;
		max-height: 45px;
		position: absolute;
		top: 40%;
		transform: translate(-50%, -50%);
		left: 50%;
	}

	&:disabled {
		background: ${props => props.theme.colors.inputDisabledBackground};
		border: 1px solid ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.inputDisabledText};
		cursor: default;

		> img {
			filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
			filter: gray;
			-webkit-filter: grayscale(100%);
		}
	}
`;

const BrandImage = styled.img`
	max-width: 110px;
	max-height: 45px;
	position: absolute;
	top: 40%;
	transform: translate(-50%, -50%);
	left: 50%;
`;

const CountWrapper = styled.div`
	font-size: 12px;
	font-family: ${props => props.theme.fonts.secondary};
	color: ${props => props.theme.colors.bodyText};
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
`;

interface BrandBlockProps {
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	onBrandClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

interface BrandFilterDialogStateProps {
	brandParameterName: string;
}

export interface BrandFilterDialogProps extends BrandBlockProps {
	brands: SearchFilterItem[];
}

type Props = BrandFilterDialogProps & BrandFilterDialogStateProps;

const BrandFilterDialog: React.FunctionComponent<Props> = ({ brands, ...rest }) => {
	return <BrandGridWrapper>{brands.map(brand => renderBrandBlock({ brand, ...rest }))}</BrandGridWrapper>;
};

const renderBrandBlock: React.FunctionComponent<BrandBlockProps &
	BrandFilterDialogStateProps & { brand: SearchFilterItem }> = ({
	brand,
	onBrandClick,
	filterCount,
	selectedFilters,
	brandParameterName,
}) => {
	const onItemClick = (filterValue: string) => {
		onBrandClick(
			brandParameterName,
			filterValue,
			!selectedFilters.some(sf => sf.name === brandParameterName && sf.value === filterValue)
		);
	};

	const highlightIcon = <Icon iconName={brand.icon} />;
	const intl = useIntl();
	return (
		<BrandButtonWrapper key={`${brand.name}=${brand.value}`}>
			<BrandButton
				onClick={onItemClick.bind(null, brand.value)}
				disabled={!filterCount[brand.value]}
				selected={selectedFilters.some(sf => sf.name === brandParameterName && sf.value === brand.value)}>
				{highlightIcon ? (
					highlightIcon
				) : (
					<BrandImage className="brand-image" src={`${brand.imageUrl}/125x70-brand-thumbnail`} alt={brand.value} />
				)}
				<CountWrapper>
					{filterCount[brand.value] || 0} {intl.formatMessage({ id: 'search_results_product_plural' })}
				</CountWrapper>
			</BrandButton>
		</BrandButtonWrapper>
	);
};

const mapStateToProps: MapStateToProps<BrandFilterDialogStateProps, {}, State> = (state: State) => {
	return {
		brandParameterName: getSettingValue(state, 'ProductSearch', 'BrandParameter') ?? 'brandi',
	};
};

export default connect(mapStateToProps)(BrandFilterDialog);
