import * as React from 'react';
import Toggle from 'styleguide/components/Inputs/Toggle/Toggle';
import ProductGroupFilterDialog from './filters/ProductGroupFilterDialog';
import { SearchFilterSection, SearchFilterItem } from '../interfaces';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import SearchFilter from './SearchFilter';
import BrandFilterDialog from './filters/BrandFilterDialog';
import { SearchFiltersContainer } from 'common/components/Search';
import SimpleFilterDialog from './filters/SimpleFilterDialog';
import AllergenicFilterDialog from './filters/AllergenicFilterDialog';
import PackageFilterDialog from './filters/PackageFilterDialog';
import { useIntl } from 'react-intl';
import { toggleMedia } from 'common/components/Media';
import styled from 'styled-components';
import DietLactoseFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietLactoseFreeIcon/DietLactoseFreeIcon';
import DietGlutenFreeIcon from 'styleguide/components/Icons/AspectGroupIcons/DietGlutenFreeIcon/DietGlutenFreeIcon';
import VegeIcon from 'styleguide/components/Icons/AspectGroupIcons/VegeIcon/VegeIcon';
import SugarIcon from 'styleguide/components/Icons/AspectGroupIcons/SugarIcon/SugarIcon';

const FILTER_ICON_MAP = {
	dietLactoseFree: <DietLactoseFreeIcon />,
	dietGlutenFree: <DietGlutenFreeIcon />,
	dietVegan: <VegeIcon />,
	dietLessSugar: <SugarIcon />,
};

const ResponsiveToggle = styled(Toggle)`
	${toggleMedia('flex', 'show', 'desktop')};
`;

interface ProductSearchFiltersProps {
	filters: SearchFilterSection[];
	filterCount: NumberMap;
	resultsCount: number;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean, items?: SearchFilterItem[]) => void;
	onRadioFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
}

const ProductSearchFilters: React.FC<ProductSearchFiltersProps> = ({
	filters,
	filterCount,
	selectedFilters,
	onFilterClick,
	onRadioFilterClick,
	isResultsLoading,
	resultsCount,
}) => {
	const intl = useIntl();

	const noveltyFilter = filters.find(f => f.type === 'Novelty');
	const productGroupFilter = filters.find(f => f.type === 'ProductGroup');
	const brandFilter = filters.find(f => f.type === 'Brand');
	const allergenicFeatureFilter = filters.find(f => f.type === 'AllergenicFeature');
	const ingredientsFilter = filters.find(f => f.type === 'Ingredient');
	const nutrientFeatureFilter = filters.find(f => f.type === 'NutrientFeature');
	const packageFeatureFilter = filters.find(f => f.type === 'PackageFeature');

	const onNoveltyFilterClick = (isActive: boolean) => {
		noveltyFilter && onFilterClick(noveltyFilter.slug!, noveltyFilter.value!, !isActive);
	};

	return (
		<SearchFiltersContainer>
			{noveltyFilter && (
				<ResponsiveToggle
					className="toggle-filter"
					initialState={selectedFilters.find(f => f.name === noveltyFilter.slug) !== undefined}
					onToggleClick={onNoveltyFilterClick}
					size={'small'}>
					{noveltyFilter.title}
				</ResponsiveToggle>
			)}
			{productGroupFilter && (
				<SearchFilter
					key={`${productGroupFilter.title}`}
					label={productGroupFilter.title}
					filterType={productGroupFilter.type}
					modalDesktopWidth="960px">
					<ProductGroupFilterDialog
						key={productGroupFilter.type}
						filterItems={productGroupFilter.items!}
						filterCount={filterCount}
						selectedFilters={selectedFilters}
						onFilterClick={onFilterClick}
						onRadioFilterClick={onRadioFilterClick}
						isResultsLoading={isResultsLoading}
					/>
				</SearchFilter>
			)}
			{brandFilter && (
				<SearchFilter key={`${brandFilter.title}`} label={brandFilter.title} modalDesktopWidth="960px">
					<BrandFilterDialog
						key={brandFilter.type}
						brands={brandFilter.items as SearchFilterItem[]}
						filterCount={filterCount}
						onBrandClick={onFilterClick}
						selectedFilters={selectedFilters}
					/>
				</SearchFilter>
			)}
			{nutrientFeatureFilter && (
				<SearchFilter
					key={`${nutrientFeatureFilter.title}`}
					label={nutrientFeatureFilter.title}
					modalDesktopWidth="960px">
					<SimpleFilterDialog
						key={nutrientFeatureFilter.type}
						filterItems={nutrientFeatureFilter.items!}
						filterCount={filterCount}
						filterIconMap={FILTER_ICON_MAP}
						selectedFilters={selectedFilters}
						onFilterClick={onFilterClick}
						isResultsLoading={isResultsLoading}
					/>
				</SearchFilter>
			)}
			{allergenicFeatureFilter && ingredientsFilter && (
				<SearchFilter
					key={`${allergenicFeatureFilter.title}`}
					label={intl.formatMessage({ id: 'product_ingredients' })}
					modalDesktopWidth="960px">
					<AllergenicFilterDialog
						key={allergenicFeatureFilter.type}
						allergenics={allergenicFeatureFilter.groups as SearchFilterItem[]}
						allergenicsTitle={allergenicFeatureFilter.title}
						ingredients={ingredientsFilter.groups as SearchFilterItem[]}
						ingredientsTitle={ingredientsFilter.title}
						filterCount={filterCount}
						onItemSelect={onFilterClick}
						selectedFilters={selectedFilters}
						resultsCount={resultsCount}
					/>
				</SearchFilter>
			)}
			{packageFeatureFilter && (
				<SearchFilter label={packageFeatureFilter.title} modalDesktopWidth="600px">
					<PackageFilterDialog
						onFilterClick={onRadioFilterClick}
						selectedFilters={selectedFilters}
						options={packageFeatureFilter.items!}
					/>
				</SearchFilter>
			)}
		</SearchFiltersContainer>
	);
};

export default ProductSearchFilters;
