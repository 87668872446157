import * as React from 'react';
import { SearchState } from '../interfaces';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import ArticleSearchFilters from './ArticleSearchFilters';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import { searchActions } from '../reducers/search-actions';
import { SearchType } from 'pagetypes/Search/types';
import { SearchActions } from 'common/components/Search';
import { RoutingState, routingActions } from 'common/components/Routing/reducers';
import { NameAndValue } from 'common/interfaces/common';
import { queryToFilters } from '../utils';
import { SEARCH_API_URL_SEARCH_QUERY, SEARCH_API_URL_SEARCH_SUBJECT, SEARCH_ARTICLES_PAGE_SIZE } from '../constants';
import InfiniteScrollHelper from 'common/components/InfiniteScroll/InfiniteScrollHelper';
import ArticleSearchResults from './ArticleSearchResults';
import { addToSearch, removeFromSearch } from 'utils/query-string';
import { getSettingValue, getSiteUrlId } from 'common/components/App/services';
import { normalizeSearchUrl, renameQueryStringParameter } from '../searchApiHelper';
import { clearHashString, getHashSearchPageOrDefault, setHashSearchPage } from '../../../utils/hash-string';
import { resumeScrollPositionFromHash, saveScrollPositionToHash } from '../../../utils/scroll';

type Props = {} & SearchArticlesStateProps & SearchArticlesDispatchProps;

class SearchArticles extends React.Component<Props> {
	private infiniteScroll: InfiniteScrollHelper;
	private initialPageCount = getHashSearchPageOrDefault();
	private currentPageNumber = getHashSearchPageOrDefault();
	private isComponentMounted = false;
	private hasResetScroll = false;
	private readonly pageSize = SEARCH_ARTICLES_PAGE_SIZE;

	constructor(props: Props) {
		super(props);
		this.infiniteScroll = new InfiniteScrollHelper(this.initialPageCount);
		this.infiniteScroll.subscribe(this.onInfiniteScroll.bind(this));
	}

	public componentDidMount() {
		const { routingState } = this.props;
		this.doSearchArticles(routingState.search || '');
		this.isComponentMounted = true;
	}

	public componentWillUnmount() {
		this.infiniteScroll.unsubscribeInfiniteScroll();
	}

	public componentDidUpdate(prevProps: Props) {
		this.infiniteScroll.unlockInfiniteScroll(prevProps.search.articles, this.props.search.articles);

		if (prevProps.routingState.search !== this.props.routingState.search) {
			this.initialPageCount = 1;
			this.currentPageNumber = 1;
			const { routingState } = this.props;
			this.infiniteScroll.reset();
			this.doSearchArticles(routingState.search || '');
		}
	}

	public render() {
		const {
			search: { isLoading, articles, count, isProfessionalSearch, isResultsLoading },
			selectedFilters,
			searchKeyword,
			searchUrl,
		} = this.props;

		return (
			<>
				<SearchActions
					renderFilters={this.renderFilters}
					resultsCount={count}
					searchType={SearchType.ARTICLE}
					onFilterClick={this.onFilterClick}
					selectedFilters={selectedFilters}
					isProfessional={isProfessionalSearch}
					inlineMobileFilters
				/>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<>
						<ArticleSearchResults
							results={articles}
							isResultsLoading={!!isResultsLoading}
							onMount={this.resumeScrollIfContentMounted}
							onArticleClick={this.savePageAndScrollPosition}
							searchUrl={searchUrl}
							searchKeyword={searchKeyword}
						/>
					</>
				)}
			</>
		);
	}

	private resumeScrollIfContentMounted = () => {
		if (this.isComponentMounted && !this.hasResetScroll) {
			resumeScrollPositionFromHash();
			clearHashString();
			this.infiniteScroll.reset(this.initialPageCount);
			this.hasResetScroll = true;
		}
	};

	private savePageAndScrollPosition = () => {
		saveScrollPositionToHash();
		setHashSearchPage(this.currentPageNumber);
	};

	private renderFilters = () => {
		const { search, selectedFilters } = this.props;
		const { availableFilters, filterCount, isResultsLoading } = search;
		return (
			<ArticleSearchFilters
				filters={availableFilters}
				filterCount={filterCount}
				selectedFilters={selectedFilters}
				isResultsLoading={isResultsLoading}
				professional={search.isProfessionalSearch}
				onFilterClick={this.onFilterClick}
			/>
		);
	};

	private onFilterClick = (filterName: string, filterValue: string, selected: boolean) => {
		const { updateSearch, routingState } = this.props;
		const search = (selected ? addToSearch : removeFromSearch)(routingState.search || '', filterName, filterValue);
		updateSearch(search);
	};

	private onInfiniteScroll = (pageNumber: number) => {
		this.currentPageNumber = pageNumber;
		const { siteUrlId, routingState, loadMoreArticles, search, resourceId } = this.props;
		const { articles, count } = search;
		if (articles.length === count || !resourceId) {
			return;
		}

		const apiSearchUrl = this.translateSearchUrlForAPICall(routingState.search);
		loadMoreArticles(siteUrlId, resourceId, apiSearchUrl || '', this.pageSize, pageNumber);
	};

	private doSearchArticles(searchUrl: string) {
		const { searchArticles, siteUrlId, resourceId } = this.props;

		if (resourceId) {
			const finalSearchUrl = this.translateSearchUrlForAPICall(searchUrl);
			searchArticles(siteUrlId, resourceId, finalSearchUrl || '', this.pageSize * this.initialPageCount);
		}
	}

	private translateSearchUrlForAPICall(search: string | undefined) {
		if (!search) {
			return search;
		}

		let searchUrl = normalizeSearchUrl(search);

		searchUrl = renameQueryStringParameter(searchUrl, this.props.searchQuery, SEARCH_API_URL_SEARCH_QUERY);
		searchUrl = renameQueryStringParameter(searchUrl, this.props.searchSubject, SEARCH_API_URL_SEARCH_SUBJECT);

		return searchUrl;
	}
}

interface SearchArticlesStateProps {
	resourceId?: string;
	search: SearchState;
	routingState: RoutingState;
	selectedFilters: NameAndValue[];
	siteUrlId: string;
	searchQuery: string;
	searchSubject: string;
	searchUrl: string;
	searchKeyword: string;
}

const mapStateToProps: MapStateToProps<SearchArticlesStateProps, {}, State> = (state: State) => {
	const { resource, routing, app } = state;
	const sites = (app.settings && app.settings.sites) || [];
	const searchQuery = getSettingValue(state, 'Search', 'SearchQuery') ?? SEARCH_API_URL_SEARCH_QUERY;
	const searchKeyword = routing.query && searchQuery ? routing.query[searchQuery]?.toString() : '';

	return {
		resourceId: resource.id,
		search: resource.content as SearchState,
		routingState: routing,
		selectedFilters: queryToFilters(routing.query || {}),
		siteUrlId: getSiteUrlId(routing, sites) || '',
		searchQuery,
		searchSubject: getSettingValue(state, 'ArticleSearch', 'Subject') ?? SEARCH_API_URL_SEARCH_SUBJECT,
		searchUrl: routing.pathname,
		searchKeyword,
	};
};

interface SearchArticlesDispatchProps {
	searchArticles: typeof searchActions.searchArticles;
	loadMoreArticles: typeof searchActions.loadMoreArticles;
	updateSearch: typeof routingActions.updateSearch;
}

export default connect(mapStateToProps, {
	searchArticles: searchActions.searchArticles,
	loadMoreArticles: searchActions.loadMoreArticles,
	updateSearch: routingActions.updateSearch,
})(SearchArticles);
