import * as React from 'react';

interface TruncateProps {
	value: string;
	truncateAt: number;
}

const Truncate: React.FC<TruncateProps> = ({ value, truncateAt }) => {
	const output = value.substr(0, truncateAt);
	if (output.length < truncateAt) {
		return <span>{output}</span>;
	}
	return (
		<>
			{output}
			<span dangerouslySetInnerHTML={{ __html: '&hellip;' }} />
		</>
	);
};

export default Truncate;
