import * as React from 'react';
import styled from 'styled-components';
import FilterCount from './FilterCount';
import { NormalIconWrapperProps } from 'styleguide/components/Icons/NormalIcons/NormalIconWrapper';

interface StyledIconFilterProps {
	selected?: boolean;
	disabled?: boolean;
}

const StyledIconFilter = styled.button<StyledIconFilterProps>`
	border: 1px solid ${props => (props.selected ? props.theme.colors.selectedItem : props.theme.colors.borderHighlight)};
	border-radius: 4px;
	background: ${props => (props.selected ? props.theme.colors.selectedItemBackground : props.theme.colors.white)};
	padding: 5px 10px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: ${props => (props.selected ? props.theme.colors.brandPrimary : props.theme.colors.buttonPrimary)};

	&:hover {
		cursor: pointer;
	}

	&:active,
	&:focus {
		outline: none;
	}

	&:disabled {
		background: ${props => props.theme.colors.inputDisabledBackground};
		border: 1px solid ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.inputDisabledText};
		cursor: default;
	}
`;

const Title = styled.span<StyledIconFilterProps>`
	font-family: ${props => props.theme.fonts.primary};
	font-size: 13px;
	display: block;
`;

export interface IconFilterProps extends StyledIconFilterProps {
	icon: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
	title: string;
	count?: number | undefined;
	filterName: string;
	filterValue: string;
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

const IconFilter: React.FC<IconFilterProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	icon,
	selected,
	title,
	count,
	filterName,
	filterValue,
	onFilterClick,
	disabled,
	...rest
}) => {
	const onClick = () => {
		if (!disabled) {
			onFilterClick(filterName, filterValue, !selected);
		}
	};

	return (
		<StyledIconFilter className="icon-filter" selected={selected} disabled={disabled} onClick={onClick} {...rest}>
			{icon}
			<Title selected={selected} disabled={disabled}>
				{title}
			</Title>
			<FilterCount>{count}</FilterCount>
		</StyledIconFilter>
	);
};

export default IconFilter;
