import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import ProductsIcon from 'styleguide/components/Icons/AspectGroupIcons/ProductsIcon/ProductsIcon';
import Pill from 'styleguide/components/Selection/Pill/Pill';
import { InputSize } from 'styleguide/types/inputTypes';
import { media } from 'styleguide/helpers/media';
import { State } from 'common/reducers';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';
import SearchResults from 'common/components/Navigation/components/SearchResults/SearchResults';
import { SearchState } from '../../interfaces';
import { searchActions } from '../../reducers/search-actions';
import { NavLinkItemProps } from 'common/components/Navigation/interfaces';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getSiteUrlPrefixOrDefault, getSiteUrlId, getSettingValue } from 'common/components/App/services';
import { SearchFilterName } from '../../types';

const RecipeProductFilterDialogWrapper = styled.div`
	display: flex;

	& > img {
		display: none;
		margin-right: 15px;

		${media.tablet`
      display: inline;
    `};
	}
`;

const ProductSearchForm = styled.form`
	width: 100%;
	padding-top: 21px;
	height: 200px;
`;

const SelectedProductWrapper = styled.div`
	margin-top: 15px;
	padding: 0 8px;
`;

interface RecipeProductFilterDialogProps {
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

interface RecipeProductFilterDialogDispatchProps {
	searchProducts: typeof searchActions.recipeProductFilterSearchProducts;
	setProduct: typeof searchActions.recipeProductFilterSetProduct;
}

interface RecipeProductFilterDialogStateProps {
	searchResult?: ProductSearchResult;
	isSearching?: boolean;
	productTitle?: string;
	productSlug?: string;
	siteUrlPrefix: string;
	siteUrlId: string;
	ingredientsParameterName: string;
}

class RecipeProductFilterDialog extends React.Component<
	RecipeProductFilterDialogProps &
		RecipeProductFilterDialogStateProps &
		RecipeProductFilterDialogDispatchProps &
		WrappedComponentProps
> {
	constructor(
		props: RecipeProductFilterDialogProps &
			RecipeProductFilterDialogStateProps &
			RecipeProductFilterDialogDispatchProps &
			WrappedComponentProps
	) {
		super(props);
		this.state = {};
	}

	public render() {
		// const { searchResult, isSearching, productTitle, productSlug } = this.props;
		const { searchResult, isSearching, productTitle, intl, siteUrlPrefix } = this.props;
		const hasProduct = !!productTitle; // && !!productSlug;

		return (
			<RecipeProductFilterDialogWrapper>
				<ProductsIcon />

				<ProductSearchForm>
					<SearchResults
						placeholder={intl.formatMessage({ id: 'search_filter_recipe_product_write_product_name' })}
						showMostPopularKeywords={false}
						mostPopularKeywords={[]}
						onSetSearchText={this.searchInputChanged}
						productResults={searchResult}
						loading={isSearching}
						className="search-results"
						itemClicked={this.itemClicked}
						autoFocus={true}
						inputSize={InputSize.medium}
						isDesktopWidthFluid={true}
						hideSearchCategoryTitle={true}
						hideSearchIcon={true}
						siteUrlPrefix={siteUrlPrefix}
					/>
					<SelectedProductWrapper>
						{hasProduct && <Pill onRemove={this.onRemove}>{productTitle}</Pill>}
					</SelectedProductWrapper>
				</ProductSearchForm>
			</RecipeProductFilterDialogWrapper>
		);
	}

	private itemClicked = (item: NavLinkItemProps) => {
		const { title, slug } = item;
		const ingredientsParameterName = this.props.ingredientsParameterName;
		this.props.onFilterClick(ingredientsParameterName, slug || '', true);
		this.props.setProduct(title, slug);
	};

	private onRemove = () => {
		const productSlug = this.props.productSlug;
		const ingredientsParameterName = this.props.ingredientsParameterName;
		this.props.setProduct('', undefined);
		this.props.onFilterClick(ingredientsParameterName, productSlug || '', false);
	};

	private searchInputChanged = (input: string) => {
		const { siteUrlId } = this.props;
		this.props.searchProducts(siteUrlId, input, 24);
	};
}

const mapStateToProps: MapStateToProps<RecipeProductFilterDialogStateProps, RecipeProductFilterDialogProps, State> = (
	state
): RecipeProductFilterDialogStateProps => {
	const { resource, app, routing } = state;
	const search = resource.content as SearchState;
	const filter = search && search.recipeProductFilter;
	const searchResult = filter && filter.searchResult;
	const sites = (app.settings && app.settings.sites) || [];
	const ingredientsParameterName =
		getSettingValue(state, 'RecipeSearch', 'IngredientsParameter') ?? SearchFilterName.ingredients;
	return {
		searchResult,
		isSearching: filter && filter.isSearching,
		productTitle: filter && filter.productTitle,
		productSlug: filter && filter.productSlug,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		siteUrlId: getSiteUrlId(routing, sites) || '',
		ingredientsParameterName,
	};
};

export default connect(mapStateToProps, {
	searchProducts: searchActions.recipeProductFilterSearchProducts,
	setProduct: searchActions.recipeProductFilterSetProduct,
})(injectIntl(RecipeProductFilterDialog));
