import React, { useState, useEffect } from 'react';
import { throttle } from 'lodash';
import { withWindow } from 'styleguide/helpers';
import styled from 'styled-components';

const ScrollMenuWrapper = styled.div<ScrollMenuState>`
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	border: 0 none;
	transition: all ease-out 0.4s;
	z-index: ${({ theme }) => theme.zIndices.popovers};

	${props =>
		props.isVisible
			? `
      bottom: 0;
    `
			: `
      bottom: -100%;
  `};
`;

interface ScrollMenuState {
	isVisible: boolean;
}

const ScrollMenu: React.FC = ({ children }) => {
	const [isVisible, setVisibleState] = useState(false);

	const onScrollWithWindow = (window: Window) => {
		if (window.scrollY > 695) {
			setVisibleState(true);
		} else if (window.scrollY < 200) {
			setVisibleState(false);
		}
	};

	const onScroll = throttle(() => withWindow(w => onScrollWithWindow(w)));

	useEffect(() => {
		withWindow(w => w.addEventListener('scroll', onScroll));

		return function cleanup() {
			withWindow(w => w.removeEventListener('scroll', onScroll));
		};
	});

	return <ScrollMenuWrapper isVisible={isVisible}>{children}</ScrollMenuWrapper>;
};

export default ScrollMenu;
