import * as React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { media } from 'styleguide/helpers';
import { styleguideImport } from 'utils/import';
import AuthorTag, { AuthorTagProps } from 'styleguide/components/Tags/AuthorTag/AuthorTag';
import HealthcareIcon from 'styleguide/components/Icons/AspectGroupIcons/HealthcareIcon/HealthcareIcon';
import { ArticleCardInterface } from 'styleguide/interfaces/cardInterfaces';
import NewsTag from 'styleguide/components/Tags/NewsTag';
import { ResourceType } from 'common/components/Resource/types';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LazyImage, LazyImageProps } from 'styleguide/components/LazyImage';
import {
	GenericHighlightCardContainerProps,
	GenericHighlightCardContainer,
	GenericHighlightCardImageContainer,
	GenericHighlightCardContent,
	GenericHighlightCardContentTag,
	truncate,
} from '../GenericHighlightCard/GenericHighlightCard';

interface ArticleProps extends GenericHighlightCardContainerProps {
	showAuthor?: boolean;
	forceSmallTitle?: boolean;
}

interface ArticleCardProps extends ArticleProps {
	theme: DefaultTheme;
	truncateLength: number;
}

interface ArticleCardState {
	highlightIcon: React.FC | React.ComponentClass | null;
}

type Props = ArticleCardProps &
	ArticleCardInterface &
	WrappedComponentProps &
	React.AnchorHTMLAttributes<HTMLAnchorElement>;

class ArticleCard extends React.Component<Props, ArticleCardState> {
	public state: ArticleCardState = {
		highlightIcon: null,
	};

	public componentDidMount() {
		const { icon } = this.props;
		this.loadIcon(icon);
	}

	public render() {
		const {
			title,
			titleHtml,
			url,
			author,
			date,
			contentFamily,
			isHealthCareContent,
			resourceType,
			truncateLength,
			onClick,
			layoutName,
			imageSize,
			showAuthor,
			forceSmallTitle,
			intl,
		} = this.props;
		const isNewsArticle = resourceType === ResourceType.newsArticle;

		const displayTitle = titleHtml ? titleHtml : title;

		return (
			<GenericHighlightCardContainer href={url} onClick={onClick} layoutName={layoutName}>
				<GenericHighlightCardImageContainer layoutName={layoutName} imageSize={imageSize}>
					{this.renderArticleImage()}
				</GenericHighlightCardImageContainer>
				<GenericHighlightCardContent layoutName={layoutName}>
					<GenericHighlightCardContentTag
						color={this.getContentTagColor()}
						size={layoutName === 'three-column' ? 'small' : undefined}>
						{isHealthCareContent && !isNewsArticle && intl.formatMessage({ id: 'page_cms_content_for_professionals' })}
						{isNewsArticle && !isHealthCareContent && intl.formatMessage({ id: 'global_news_item' })}
						{!isHealthCareContent && !isNewsArticle && contentFamily}
					</GenericHighlightCardContentTag>
					<ArticleTitle
						layoutName={layoutName}
						showAuthor={showAuthor}
						forceSmallTitle={forceSmallTitle}
						dangerouslySetInnerHTML={{ __html: truncate(displayTitle, truncateLength) }}
					/>
					{showAuthor && isNewsArticle && date && (
						<NewsTag
							className="article-highlight-news-tag"
							date={date}
							size={layoutName === 'three-column' ? 'small' : undefined}
						/>
					)}
					{showAuthor && author && !isNewsArticle && (
						<ArticleAuthorTag {...author} date={date} size={layoutName === 'three-column' ? 'small' : undefined} />
					)}
				</GenericHighlightCardContent>
			</GenericHighlightCardContainer>
		);
	}

	private renderArticleImage() {
		const { isHealthCareContent, icon, layoutName, imageUrl, theme } = this.props;
		const { highlightIcon } = this.state;

		const Icon = highlightIcon ? highlightIcon : 'span';

		let background: React.CSSProperties = {};

		if (isHealthCareContent) {
			background = {
				backgroundColor: `${theme.colors.professionalContentBackground}`,
			};
		}

		if (icon) {
			background = {
				backgroundColor: `${theme.colors.brandPrimaryFeatureBackground}`,
			};
		}

		if (isHealthCareContent || icon) {
			return (
				<ArticleImage style={background} layoutName={layoutName}>
					{isHealthCareContent && <HealthcareIcon />}
					{icon && <Icon />}
				</ArticleImage>
			);
		}

		const renderImage = ({ src }: LazyImageProps) => {
			const styles = {
				backgroundImage: `url(${src})`,
			};

			return <ArticleImage style={styles} layoutName={layoutName} />;
		};

		return <LazyImage src={`${imageUrl}/300x300-article-highlight`} render={renderImage} />;
	}

	private async loadIcon(icon: string | null) {
		if (icon) {
			const styleGuideIcon = await styleguideImport(icon);
			if (styleGuideIcon) {
				this.setState({ highlightIcon: styleGuideIcon });
			}
		}
	}

	private getContentTagColor() {
		const { isHealthCareContent, resourceType, icon, themeColor, theme } = this.props;

		if (isHealthCareContent) {
			return theme.colors.professionalContent;
		}

		if (icon || resourceType === ResourceType.newsArticle) {
			return theme.colors.brandPrimary;
		}

		return themeColor;
	}
}

export default injectIntl(withTheme(ArticleCard));

const ArticleAuthorTag = styled(AuthorTag)`
	margin-top: 5px;
	position: absolute;
	bottom: 5px;

	${media.desktop<AuthorTagProps>`
		bottom: ${props => (props.size === 'small' ? 5 : 20)}px;
	`};
`;

const ArticleImage = styled.div<GenericHighlightCardContainerProps>`
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const ArticleTitle = styled.div<ArticleProps>`
	margin-top: -10px;
	font-size: 18px;
	line-height: 21px;
	font-weight: 600;
	letter-spacing: -0.2px;
	text-decoration: none;

	${media.desktop<ArticleProps>`
		${props =>
			(props.layoutName === 'two-column' || !props.showAuthor) && !props.forceSmallTitle
				? `
					font-size: 24px;
					line-height: 26px;
					letter-spacing: -0.2px;
				`
				: ''}
	`};
`;
