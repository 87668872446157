import * as React from 'react';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
// import ResourceLoading from 'common/components/Resource/components/ResourceLoading';
// import SearchResultsGrid from './SearchResultsGrid';
import NoResults from './NoResults';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { ArticleCardInterface } from 'styleguide/interfaces/cardInterfaces';
import ArticleHighlight from 'common/components/Widgets/ArticleHighlight';

const ArticleSearchResultsWrapper = styled.div`
	width: 100%;
	/* padding: ${props => props.theme.grid.gutterInPx(4)} 0; */

	.article-highlight-wrapper {
		margin: 0 auto;

		${media.tablet`
			margin: 0 -10px;
		`};

		${media.desktop1440`
			margin: 0 -20px;
		`};
	}

	.article-search-spinner {
		grid-column: 1 / -1;
	}
`;

export interface Props {
	results: ArticleCardInterface[];
	isResultsLoading: boolean;
	onArticleClick: () => void;
	onMount: () => void;
	searchUrl?: string;
	searchKeyword?: string;
}

class ArticleSearchResults extends React.Component<Props & InjectedRoutableProps> {
	public render() {
		const { results, isResultsLoading, searchKeyword, searchUrl } = this.props;
		if (!isResultsLoading && results.length === 0) {
			return (
				<NoResults
					searchUrl={searchUrl}
					searchKeyword={searchKeyword}
					noResultsButtonLabel="component_no_results_article_button"
					noResultsDescription="search_no_results_articles_with_keyword"
				/>
			);
		}

		return (
			<ArticleSearchResultsWrapper>
				<ArticleHighlight
					zone="article-search-results"
					widgetId="article-search-results"
					articles={results}
					layoutName="three-column"
					isLoading={isResultsLoading}
					isLeftAligned={true}
					showAuthor
					onArticleClick={this.props.onArticleClick}
					onMount={this.props.onMount}
				/>
			</ArticleSearchResultsWrapper>
		);
	}
}

export default injectRoutable(ArticleSearchResults);
