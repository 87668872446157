import * as React from 'react';
import styled from 'styled-components';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import { media } from 'styleguide/helpers/media';
import Select from 'styleguide/components/Inputs/Select/Select';
import { ProductFeatures, ProductFeature } from '../interfaces';
import { FormattedMessage, useIntl } from 'react-intl';

const IngredientSelectorsWrapper = styled.div`
	margin: ${({ theme }) => theme.grid.gutterInPx(3)} auto;

	${media.tablet`
		margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;
		display: flex;
		justify-content: space-between;
		align-content: space-between;
  `};
`;

const IngredientSelectorWell = styled.div`
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	border: 1px solid ${({ theme }) => theme.colors.border};
	border-radius: 4px;
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
		margin-top: 0;
		flex: 1 1 auto;
		flex: 1 1 calc(50% - ${({ theme }) => theme.grid.gutterInPx(1.5)});
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(1.5)});
		padding: ${({ theme }) => theme.grid.gutterInPx(3)};

		& + & {
			margin-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		}
	`};

	${media.desktop`
		flex: 1 1 auto;
		flex: 1 1 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
		padding: ${({ theme }) => theme.grid.gutterInPx(4)};

		& + & {
			margin-left: ${({ theme }) => theme.grid.gutterInPx(4)};
		}
	`};

	.form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	}
`;

const IngredientSelectorField: React.FC<{
	label: string;
	filter: string;
	selection: ProductFeature[];
	disableZeroCounts?: boolean;
	onIngredientSelect: (value: string, filterType: string) => void;
}> = ({ label, filter, selection, onIngredientSelect, disableZeroCounts }) => {
	const intl = useIntl();

	const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		onIngredientSelect(value, filter);
	};

	return (
		<Select name={filter} label={label} onChange={onSelect} inlineLabel>
			<option value="">{intl.formatMessage({ id: 'global_choose' })}</option>
			{selection.map((item, i) => (
				<option key={i} value={item.slug} disabled={!!disableZeroCounts && item.count === 0}>
					{item.count !== undefined ? `${item.title} (${item.count})` : item.title}
				</option>
			))}
		</Select>
	);
};

interface IngredientSelectorsProps {
	aspects: ProductFeatures;
	onIngredientSelect: (value: string, filterType: string) => void;
}

const IngredientSelectors: React.FC<IngredientSelectorsProps> = ({ aspects, onIngredientSelect }) => {
	const intl = useIntl();
	return (
		<IngredientSelectorsWrapper>
			<IngredientSelectorWell>
				<SmallHeader>
					<FormattedMessage id="product_ingredients_allergy_or_intolerance_ingredients" />
				</SmallHeader>
				<IngredientSelectorField
					label={intl.formatMessage({ id: 'product_contains' })}
					filter="yliherkkyys-sisaltaa"
					selection={aspects.allergenics.contains}
					onIngredientSelect={onIngredientSelect}
				/>
				<IngredientSelectorField
					label={intl.formatMessage({ id: 'product_does_not_contain' })}
					filter="yliherkkyys-eisisalla"
					selection={aspects.allergenics.doesNotContain}
					onIngredientSelect={onIngredientSelect}
				/>
			</IngredientSelectorWell>
			<IngredientSelectorWell>
				<SmallHeader>
					<FormattedMessage id="product_other_ingredients" />
				</SmallHeader>
				<IngredientSelectorField
					label={intl.formatMessage({ id: 'product_contains' })}
					filter="muut-sisaltaa"
					selection={aspects.ingredients.contains}
					onIngredientSelect={onIngredientSelect}
				/>
				<IngredientSelectorField
					label={intl.formatMessage({ id: 'product_does_not_contain' })}
					filter="muut-eisisalla"
					selection={aspects.ingredients.doesNotContain}
					onIngredientSelect={onIngredientSelect}
				/>
			</IngredientSelectorWell>
		</IngredientSelectorsWrapper>
	);
};

export { IngredientSelectors as default, IngredientSelectorField };
