import * as React from 'react';
import { SearchFiltersContainer } from 'common/components/Search';
import { SearchFilterSection } from '../interfaces';
import { generate } from 'shortid';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import { media } from 'styleguide/helpers/media';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import PillFilters from './filters/PillFilters';
import styled from 'styled-components';

const ArticleSearchFiltersContainer = styled(SearchFiltersContainer)<{ isCentered?: boolean }>`
	justify-content: ${props => (props.isCentered ? 'center' : 'space-between')};

	${media.desktop`
		padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	${media.desktop1440`
		padding: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	`};
`;

const FilterGroup = styled.section`
	text-align: center;

	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx()};

		${media.desktop`
			margin-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		`};
	}
`;

const PillFilterWrapper = styled.div`
	text-align: center;

	${media.desktop`
		margin: 0 0 -3px;
	`};

	& > button {
		margin: 3px;
	}
`;

export interface Props {
	filters: SearchFilterSection[];
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
	professional: boolean;
}

const ArticleSearchFilters: React.FC<Props> = ({
	filters,
	filterCount,
	selectedFilters,
	isResultsLoading,
	onFilterClick,
}) => {
	return (
		<ArticleSearchFiltersContainer isCentered={filters.length === 1}>
			{filters.map(({ title, items }) => (
				<FilterGroup key={generate()}>
					{filters.length > 1 && (
						<header>
							<SmallHeader>{title}</SmallHeader>
						</header>
					)}
					<PillFilterWrapper>
						<PillFilters
							filters={items}
							selectedFilters={selectedFilters}
							isResultsLoading={isResultsLoading}
							onFilterClick={onFilterClick}
							disableCount
						/>
					</PillFilterWrapper>
				</FilterGroup>
			))}
		</ArticleSearchFiltersContainer>
	);
};

export default ArticleSearchFilters;
