import * as React from 'react';
import IconFilter from './IconFilter';
import styled from 'styled-components';
import { NameAndValue } from 'common/interfaces/common';
import { SearchFilterItem } from 'pagetypes/Search/interfaces';
import { Icon } from 'utils/import';

const IconFilterGrid = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin: 0 -6px;

	.icon-filter {
		flex: 1 1 159px;
		margin: 0 6px;

		> img {
			margin: 25px 0;
			display: block;
		}
	}
`;

export interface PackageFilterDialogProps {
	options: SearchFilterItem[];
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
}

const PackageFilterDialog: React.FC<PackageFilterDialogProps> = ({ options, selectedFilters, onFilterClick }) => {
	const onClick = (filterName: string, filterValue: string, selected: boolean) => {
		onFilterClick(filterName, filterValue, selected);
	};
	return (
		<IconFilterGrid>
			{options.map((o, i) => (
				<IconFilter
					key={i}
					filterName={o.slug!}
					filterValue={o.value}
					title={o.title}
					icon={<Icon iconName={o.icon} />}
					onFilterClick={onClick}
					selected={selectedFilters.some(sf => sf.name === o.slug && sf.value === o.value)}
				/>
			))}
		</IconFilterGrid>
	);
};

export default PackageFilterDialog;
