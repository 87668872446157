import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';

export interface AuthorTagStyleProps {
	size?: 'small' | 'large';
}

export const AuthorTagWrapper = styled.div<AuthorTagStyleProps>`
	display: flex;
	align-items: center;
	margin-top: 10px;

	${media.desktop<AuthorTagStyleProps>`
		${props =>
			props.size === 'small'
				? ''
				: css`
						margin-top: 20px;
				  `}
  `};
`;

export const AuthoredDate = styled.time<AuthorTagStyleProps>`
	font-size: 12px;
	font-weight: normal;
	font-style: italic;
	line-height: 1.25;
	color: ${props => props.theme.colors.bodyTextLight};
	display: block;

	${media.phone<AuthorTagStyleProps>`
		line-height: ${props => (props.size === 'small' ? '1.25' : '20px')};
	`};

	${media.desktop<AuthorTagStyleProps>`
		${props =>
			props.size === 'small'
				? ''
				: css`
						font-size: 13px;
				  `}
  `};
`;
