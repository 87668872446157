export function normalizeSearchUrl(searchUrl: string) {
	if (searchUrl.startsWith('?')) {
		return searchUrl.substr(1);
	}
	return searchUrl;
}

export function translateQueryStringParameter(
	search: string,
	originalKey: string,
	newKey: string,
	translation: { [id: string]: string }
): string {
	if (search.length === 0) {
		return search;
	}

	const segments = search.split('&');
	const lookupValue = `${originalKey}=`;
	const replaceIndex = segments.findIndex(s => s.startsWith(lookupValue));

	if (replaceIndex !== -1) {
		const originalValue = segments[replaceIndex].substr(lookupValue.length);
		if (translation.hasOwnProperty(originalValue)) {
			segments[replaceIndex] = `${newKey}=${translation[originalValue]}`;
		}
	}

	return segments.join('&');
}

export function renameQueryStringParameter(url: string, originalKey: string, newKey: string): string {
	if (url.length === 0) {
		return url;
	}

	const segments = url.split('&');

	let lastIndex = -2;
	while (true) {
		const lookupValue = `${originalKey}=`;
		const replaceIndex = segments.findIndex(s => s.startsWith(lookupValue));

		if (replaceIndex === lastIndex) {
			break;
		}
		lastIndex = replaceIndex;

		if (replaceIndex !== -1) {
			const originalValue = segments[replaceIndex].substr(lookupValue.length);
			segments[replaceIndex] = `${newKey}=${originalValue}`;
		} else {
			break;
		}
	}

	return segments.join('&');
}
